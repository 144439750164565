import React from 'react';
import { useNavigate } from 'react-router';

import {
    Avatar,
    AvatarFallback,
    AvatarImage,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '../../../shared/ui/ui';
import MobileSidebar from './mobile-sidebar';
import { useAuth } from '../../../shared/utils/auth';
import { routes } from '../../../shared/utils/routes';

const Navbar = () => {
    const navigate = useNavigate();
    const { removeToken, setUser } = useAuth();

    const handleLogOut = () => {
        setUser({ email: '', idUser: 0 });
        removeToken();
        navigate(routes.login.path, { replace: true });
    };
    return (
        <nav className="fixed left-0 right-0 top-0 z-50 flex h-16 items-center justify-between border-b bg-white px-6 lg:left-72">
            <div className="flex w-full items-center justify-between">
                <div className="block lg:hidden">
                    <MobileSidebar />
                </div>
                <div className="ml-auto">
                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <Avatar>
                                <AvatarImage src="/logo.png" />
                                <AvatarFallback>CN</AvatarFallback>
                            </Avatar>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuLabel>Moje konto</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem>Ustawienia</DropdownMenuItem>
                            <DropdownMenuItem>Warunki korzystania</DropdownMenuItem>
                            <DropdownMenuItem onClick={handleLogOut}>Wyloguj</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
