import React from 'react';

import { LoadingSpinner } from './loading-spinner';

type DataRendererProps = {
    children: React.ReactNode;
    error: boolean;
    isLoading: boolean;
};

const DataRenderer = ({ children, error, isLoading }: DataRendererProps) => {
    if (isLoading) {
        return (
            <div className="flex min-h-full items-center justify-center">
                <LoadingSpinner />
            </div>
        );
    }

    if (error) {
        return <div className="flex min-h-full items-center justify-center text-center">{error}</div>;
    }

    return <>{children}</>;
};

export default DataRenderer;
