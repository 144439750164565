import { useQuery } from 'react-query';
import { z } from 'zod';

import { queryKey } from '../comments.query-keys';
import { getAllComments } from '../infrastructure/get-all-comments';

export const useGetCommentsQuery = () => {
    return useQuery({
        queryKey: queryKey.comments(),
        queryFn: getAllComments,
        onError(error) {
            if (error instanceof z.ZodError) {
                console.log('error from useGetCommentsQuery', error);
            }
        },
    });
};
