import { useMutation } from 'react-query';

import { useInvalidateAllChapters } from './utils/invalidate-all-chapters';

import { updateChapterGroup } from '../infrastructure/update-chapter-group';

export const useUpdateChapterGroupMutation = () => {
    const invalidateAll = useInvalidateAllChapters();

    return useMutation({
        mutationFn: updateChapterGroup,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
