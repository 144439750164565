import { useMutation } from 'react-query';

import { deleteJustification } from '../infrastructure/delete-justifications';
import { useInvalidateAllJustifications } from './utils/invalidate-all-justifications';

export const useDeleteJustificationMutation = () => {
    const invalidateAll = useInvalidateAllJustifications();

    return useMutation({
        mutationFn: deleteJustification,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
