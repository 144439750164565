import { useMutation } from 'react-query';

import { useInvalidateAllChapters } from './utils/invalidate-all-chapters';
0;
import { createChapterGroup } from '../infrastructure/create-chapter-group';

export const useAddChapterGroupMutation = () => {
    const invalidateAll = useInvalidateAllChapters();

    return useMutation({
        mutationFn: createChapterGroup,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
