import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../shared/ui/ui/button';
import { Input } from '../../../shared/ui/ui/input';
import { LoginFormSchema, loginSchema } from '../components/login.schema';
import { useAuth } from '../../../shared/utils/auth';
import { routes } from '../../../shared/utils/routes';
import { useLoginMutation } from '../data-access/mutations/login.mutation';

const LoginPage = () => {
    const navigate = useNavigate();
    const { setUser, setToken } = useAuth();

    const {
        formState: { errors, isSubmitting },
        register,
        handleSubmit,
    } = useForm<LoginFormSchema>({
        resolver: zodResolver(loginSchema),
    });
    const { mutateAsync: login } = useLoginMutation();

    const onSubmit = async (data: LoginFormSchema) => {
        try {
            const response = await login(data);
            setUser({
                email: response.login,
                idUser: response.idUser,
            });
            setToken(response.token);
            navigate(routes.docs.path);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className="flex h-screen flex-1 flex-col items-center justify-center px-6 py-12 lg:px-8">
            <div className=" mx-auto w-full max-w-md">
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Zaloguj się do swojego konta
                </h2>
            </div>

            <div className="mx-auto mt-10 w-full max-w-md">
                <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <Input placeholder="Email" {...register('email')} />
                        {errors['email'] && <div className="mt-2 text-sm text-red-500">{errors['email'].message}</div>}
                    </div>
                    <div>
                        <Input type="password" placeholder="Hasło" {...register('password')} />
                        {errors['password'] && (
                            <div className="mt-2 text-sm text-red-500">{errors['password'].message}</div>
                        )}
                    </div>
                    <Button disabled={isSubmitting} type="submit" variant="default" className="mt-4 flex w-full">
                        Zaloguj się
                    </Button>
                    {errors.root && <div className="text-center text-sm text-red-500">{errors.root.message}</div>}
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
