import { useMutation } from 'react-query';

import { createDocumentChapter } from '../infrastructure/create-document-chapter';
import { useInvalidateAllDocuments } from './utils/invalidate-all-documents';

export const useAddDocumentChapterMutation = () => {
    const invalidateAll = useInvalidateAllDocuments();

    return useMutation({
        mutationFn: createDocumentChapter,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
