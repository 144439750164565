import { useQuery } from 'react-query';
import { z } from 'zod';

import { getJustificationhById } from '../infrastructure/get-justifications';
import { queryKey } from '../justifications.query-keys';

export const useGetJustificationQuery = (justificationId: string, isEnabled = true) => {
    return useQuery({
        queryKey: [queryKey.justifications(), justificationId],
        queryFn: () => getJustificationhById(justificationId),
        onError(error) {
            if (error instanceof z.ZodError) {
                console.log('error from useGetJustificationQuery', error);
            }
        },
        enabled: isEnabled,
    });
};
