import { useMutation } from 'react-query';

import { createJustification } from '../infrastructure/create-justifications';
import { useInvalidateAllJustifications } from './utils/invalidate-all-justifications';

export const useAddJustificationMutation = () => {
    const invalidateAll = useInvalidateAllJustifications();

    return useMutation({
        mutationFn: createJustification,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
