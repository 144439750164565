import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import DataRenderer from '../../../shared/ui/data-renderer';
import { Header, TableContent } from '../../../shared/ui/Table/table-content';
import { routes } from '../../../shared/utils/routes';
import DocumentListElement from '../components/document-list-element.component';
import { Document } from '../data-access/models/document';
import { useDeleteDocumentMutation } from '../data-access/mutatuions/delete-documents.mutation';
import { useGetDocumentsQuery } from '../data-access/queries/get-all-documents.query';

const DocumentList = () => {
    const navigate = useNavigate();
    const { mutateAsync: deleteDocument } = useDeleteDocumentMutation();
    const { data: documents, isLoading, isError } = useGetDocumentsQuery();

    const handleDelete = async (document: Document) => {
        try {
            await deleteDocument(document);
            toast.success('Dokument został pomyślnie usnuięty');
        } catch (error) {
            toast.error('Nie udało się usunąć dokumentu');
        }
    };

    const handleAddDocument = () => {
        navigate({
            pathname: routes.docs.add?.path,
        });
    };

    const sortedDocuments: Document[] = useMemo(() => {
        return documents
            ? [...documents].sort((a, b) => new Date(b.updatedTime!).getTime() - new Date(a.updatedTime!).getTime())
            : [];
    }, [documents]);

    const tableHeaders: Header[] = [
        { id: 1, title: 'Nazwa' },
        { id: 2, title: 'Opis' },
    ];

    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <TableContent
                headingTitle="Akty prawne"
                headingAddLabel="Dodaj nowy szablon"
                onHeadingAdd={handleAddDocument}
                tableHeaders={tableHeaders}
                tableBodyContent={sortedDocuments.map((document) => (
                    <DocumentListElement key={document.idDocument} document={document} onDelete={handleDelete} />
                ))}
            />
        </DataRenderer>
    );
};
export default DocumentList;
