import { useQuery } from 'react-query';
import { z } from 'zod';

import { getParagraphById } from '../infrastructure/get-paragraph';
import { queryKey } from '../paragraphs.query-keys';

export const useGetParagraphQuery = (paragraphId: string) => {
    return useQuery({
        queryKey: [queryKey.paragraphs(), paragraphId],
        queryFn: () => getParagraphById(paragraphId),
        onError(error) {
            if (error instanceof z.ZodError) {
                console.log('error from useGetParagraphsQuery', error);
            }
        },
    });
};
