import React from 'react';
import { useGetUnitQuery } from '../data-access/queries/get-unit.query';
import DataRenderer from '../../../shared/ui/data-renderer';
import { Button } from '../../../shared/ui/ui';
import { useNavigate } from 'react-router';
import { routes } from '../../../shared/utils/routes';

const UnitView = () => {
    const navigate = useNavigate();
    const { data: unit, isLoading, isError } = useGetUnitQuery('1');

    const handleNavigationEditUnit = () => {
        navigate({
            pathname: routes.unit.edit?.url(unit?.idAdministrativeUnit.toString() || '1'),
        });
    };
    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <div className="flex w-full justify-between px-4 sm:px-0">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Dane jednostki</h2>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <Button onClick={handleNavigationEditUnit}>Edytuj dane</Button>
                </div>
            </div>
            <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Nazwa</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{unit?.unitName}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Organ wykonawczy</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {unit?.executiveOrgan}
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Województwo</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {unit?.stateName}
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Nazwa urzędu</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {unit?.officeName}
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Organ uchwałodawczy</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {unit?.legislativeUnitName}
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Organ wykonawczy</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {unit?.executiveUnitName}
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Adres</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{unit?.address}</dd>
                    </div>
                </dl>
            </div>
        </DataRenderer>
    );
};

export default UnitView;
