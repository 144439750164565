import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';

import { cn } from '../../../lib/utils';
import { Button, Dialog, DialogContent, DialogTrigger, Input, Label } from '../../../shared/ui/ui';
import { ArrowUp } from 'lucide-react';
import { JustificationhFormValues } from './justification-form.schema';
import TextEditor from '../../../shared/ui/TextEditor/TextEditor';
import { useAddJustificationMutation } from '../data-access/mutatuions/add-justifications.mutation';

type AddJustificationModalProps = {
    triggerIcon?: React.ReactElement;
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
};

const AddJustificationModal = (props: AddJustificationModalProps) => {
    const { isOpen, onOpenChange, triggerIcon } = props;
    const [text, setText] = useState<string>('');
    const { mutateAsync: addJustification } = useAddJustificationMutation();
    const { register, handleSubmit, reset } = useForm<JustificationhFormValues>();

    useEffect(() => {
        if (!isOpen) {
            reset();
            setText('');
        }
    }, [isOpen, reset]);

    async function onSubmit(data: JustificationhFormValues) {
        const updatedData = {
            ...data,
            text: text,
        };
        try {
            await addJustification(updatedData);
            onOpenChange(false);
            toast.success('Uzasadnienie zostało dodane.');
        } catch (error) {
            toast.error(`Błąd przy dadawaniu uzasadnienia`);
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogTrigger>{triggerIcon ? triggerIcon : <ArrowUp />}</DialogTrigger>
            <DialogContent className={cn('max-h-[800px] w-full overflow-x-auto py-8 md:px-12 md:py-16')}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-2 flex flex-col justify-start gap-8">
                        <div className="flex items-center justify-between">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">Dodaj nowe uzasadnienie</h3>
                            <Button type="submit">Zapisz</Button>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-2">
                                    <Label className="mb-1">Nazwa</Label>
                                    <Input {...register('name')} />
                                </div>
                                <div>
                                    <TextEditor
                                        editorState={text}
                                        editable={true}
                                        setEditorState={setText}
                                    ></TextEditor>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default AddJustificationModal;
