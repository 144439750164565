import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import DataRenderer from '../../../shared/ui/data-renderer';

import { Button, Input, Label } from '../../../shared/ui/ui';
import { Paragraph } from '../../Paragraphs/data-access/models/paragraph';
import { useGetParagraphsQuery } from '../../Paragraphs/data-access/queries/get-all-paragraphs.query';
import GroupParagraphListElement from '../components/group-paragrpah-list-element.component';
import { GroupParagraph } from '../data-access/models/group-paragraph';
import { useAddGroupParagraphMutation } from '../data-access/mutatuions/add-group-paragraph.mutation';
import { useGetParagraphsForGroupQuery } from '../data-access/queries/get-paragraph-for-groups.query';
import ItemSelector from '../../../shared/ui/item-selector';
import { Group } from '../data-access/models/group';
import { GroupFormValues } from '../components/group-form.schema';
import { routes } from '../../../shared/utils/routes';
import { useUpdateGroupParagraphMutation } from '../data-access/mutatuions/edit-group-paragraph.mutation';
import { useUpdateGroupMutation } from '../data-access/mutatuions/edit-group.mutation';
import { useGetGroupsQuery } from '../data-access/queries/get-all-groups.query';
import { useGetGroupParagraphsQuery } from '../data-access/queries/get-group-paragraphs.query';

const GroupEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { data: groups } = useGetGroupsQuery();
    const { data: allQueryParagraphs, isLoading, isError } = useGetParagraphsQuery();
    const { data: groupQueryParagraphs, refetch } = useGetParagraphsForGroupQuery(id!);
    const { data: chapt } = useGetGroupParagraphsQuery('2');

    const [group, setGroup] = useState<Group>();
    const [paragraphs, setParagraphs] = useState<Paragraph[]>([]);
    const [allParagraphs, setAllParagraphs] = useState<Paragraph[]>([]);
    const [selectedFromAllParagraphs, setSelectedFromAllParagraphs] = useState<number[]>([]);
    const [selectedFromUsedGroups, setSelectedFromUsedGroups] = useState<number[]>([]);
    const [selectedParagraphs, setSelectedParagraphs] = useState<Paragraph[]>([]);
    const [groupParagraph, setGroupParagraph] = useState<GroupParagraph[]>([]);

    const { mutateAsync: addGroupParagraph } = useAddGroupParagraphMutation();
    const { mutateAsync: updateGroupParagraph } = useUpdateGroupParagraphMutation();
    const { mutateAsync: updateGroup } = useUpdateGroupMutation();

    const { register, handleSubmit, reset } = useForm<GroupFormValues>();

    useEffect(() => {
        if (groups && id) {
            const filteredGroup = groups.find((group: Group) => group.idGroup === parseInt(id));
            setGroup(filteredGroup);
            if (filteredGroup) {
                reset({ name: filteredGroup.name });
            }
        }

        if (groupQueryParagraphs && allQueryParagraphs) {
            const sortedParagraphs = [...groupQueryParagraphs].sort((a, b) => a.order - b.order);
            setParagraphs(sortedParagraphs);

            const unusedParagraphs = allQueryParagraphs.filter(
                (para) => !groupQueryParagraphs.some((el) => el.idParagraph === para.idParagraph),
            );
            setAllParagraphs(unusedParagraphs);
        }

        if (chapt && id) {
            const filteredDocumentChapters = chapt.filter((doc: GroupParagraph) => doc.idGroup === Number(id));
            setGroupParagraph(filteredDocumentChapters);
        }

        if (groupQueryParagraphs) {
            const filteredParagraphs = groupQueryParagraphs.filter((para) =>
                selectedFromUsedGroups.includes(para.idParagraph),
            );
            if (filteredParagraphs) {
                setSelectedParagraphs(filteredParagraphs);
            }
        }
    }, [groups, id, groupQueryParagraphs, allQueryParagraphs, chapt, selectedFromUsedGroups, reset]);

    async function onSubmit(data: GroupFormValues) {
        try {
            const groupData: Group = {
                ...data,
                idGroup: +id!,
                ChapterGroups: group?.ChapterGroups || [],
                GroupParagraphs: group?.GroupParagraphs || [],
            };
            await updateGroup(groupData);

            const groupParagraphs: GroupParagraph[] = selectedParagraphs.map((para, index) => {
                const existingParagraph = groupParagraph?.find((gp) => gp.idParagraph === para.idParagraph);

                return {
                    idGroup: +id!,
                    idParagraph: para.idParagraph,
                    order: index + 1,
                    idGroupParagraph: existingParagraph ? existingParagraph.idGroupParagraph : 0,
                };
            });

            for (const groupParagraph of groupParagraphs) {
                if (groupParagraph.idGroupParagraph === 0) {
                    await addGroupParagraph(groupParagraph);
                } else {
                    await updateGroupParagraph(groupParagraph);
                }
            }

            toast.success('Grupa została zaktualizowana.');
            navigate({ pathname: routes.group.path });
        } catch (error) {
            toast.error(`Błąd przy tworzeniu grupy`);
        }
    }

    const handleItemsChange = (updatedItems: Paragraph[]) => {
        setSelectedParagraphs(updatedItems);
    };

    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-8">
                    <div className="border-b border-gray-900/10 pb-8">
                        <div className="mb-2 flex items-center justify-between">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Dane grupy</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">Edytuj dane grupy.</p>
                            </div>
                            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                <Button type="submit">Zapisz</Button>
                            </div>
                        </div>
                        <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                            <div className="sm:col-span-6">
                                <Label>Nazwa</Label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ">
                                        <Input defaultValue={group?.name} {...register('name')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ItemSelector
                        allItemsQuery={allQueryParagraphs!}
                        assignedItemsQuery={paragraphs}
                        itemType="Paragraf"
                        itemKey="idParagraph"
                        itemNameKey="name"
                        selectedFromAllItems={selectedFromAllParagraphs}
                        setSelectedFromAllItems={setSelectedFromAllParagraphs}
                        selectedFromUsedItems={selectedFromUsedGroups}
                        setSelectedFromUsedItems={setSelectedFromUsedGroups}
                        onSelectedItemsChange={handleItemsChange}
                        renderListItem={(item, selected, setSelected) => (
                            <GroupParagraphListElement
                                selected={selected}
                                setSelected={setSelected}
                                key={item.idParagraph}
                                paragraph={item}
                                add={true}
                            />
                        )}
                    />
                </div>
            </form>
        </DataRenderer>
    );
};

export default GroupEdit;
