import { useMutation } from 'react-query';

import { useInvalidateAllGroups } from './utils/invalidate-all-groups';
import { updateGroupParagraph } from '../infrastructure/update-group-paragraph';

export const useUpdateGroupParagraphMutation = () => {
    const invalidateAll = useInvalidateAllGroups();

    return useMutation({
        mutationFn: updateGroupParagraph,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
