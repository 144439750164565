import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import DataRenderer from '../../../shared/ui/data-renderer';
import {
    Button,
    Input,
    Label,
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '../../../shared/ui/ui';
import { routes } from '../../../shared/utils/routes';
import { useGetUnitQuery } from '../data-access/queries/get-unit.query';
import { useUpdateUnitMutation } from '../data-access/mutations/edit-unit.mutation';
import { UnitSchemaFormValues } from '../components/unit.schema';
import { useAuth } from '../../../shared/utils/auth';
import { provinceList } from '../components/const';

const UnitEditView = () => {
    const navigate = useNavigate();
    const { user } = useAuth();

    const { data: unit, isLoading, isError } = useGetUnitQuery('1');
    const { mutateAsync: updateUnit } = useUpdateUnitMutation();

    const [selectedProvince, setSelectedProvince] = useState(unit?.stateName);
    const handleChange = (event: string) => {
        setSelectedProvince(event);
    };
    const { register, handleSubmit } = useForm<UnitSchemaFormValues>({
        defaultValues: {
            unitName: unit?.unitName,
            executiveOrgan: unit?.executiveOrgan,
            officeName: unit?.officeName,
            legislativeUnitName: unit?.legislativeUnitName,
            executiveUnitName: unit?.executiveUnitName,
            address: unit?.address,
            stateName: unit?.stateName,
        },
    });

    async function onSubmit(data: UnitSchemaFormValues) {
        const updatedData = {
            ...data,
            idUser: user.idUser,
        };

        try {
            await updateUnit(updatedData);
            toast.success('Dane jednostki zostały zaktualizowane.');
            navigate({ pathname: routes.unit.path });
        } catch (error) {
            toast.error(`Błąd przy aktualizacji danych jednostki.`);
        }
    }

    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4 flex items-center justify-between">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Dane jednostki</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Edytuj dane jednostki.</p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <Button type="submit">Zapisz</Button>
                    </div>
                </div>
                <div className="whitespace-wrap w-full py-4 pl-0 pr-0 text-sm text-gray-900 sm:pl-0 md:pl-0 md:pr-3">
                    <div className="flex flex-col gap-4">
                        <div className="space-y-2">
                            <Label className="mb-1">Nazwa jednostki</Label>
                            <Input defaultValue={unit?.unitName} {...register('unitName')} />
                        </div>
                        <div className="space-y-2">
                            <Label className="mb-1">Organ wykonawczy</Label>
                            <Input defaultValue={unit?.executiveOrgan} {...register('executiveOrgan')} />
                        </div>
                        <div className="space-y-2">
                            <Label className="mb-1">Województwo</Label>
                            <Select onValueChange={handleChange}>
                                <SelectTrigger className="w-full">
                                    <SelectValue placeholder={selectedProvince} />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectLabel>Województwa</SelectLabel>
                                        {provinceList.map((province) => (
                                            <SelectItem key={province} value={province}>
                                                {province}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="space-y-2">
                            <Label className="mb-1">Nazwa urzędu</Label>
                            <Input defaultValue={unit?.officeName} {...register('officeName')} />
                        </div>
                        <div className="space-y-2">
                            <Label className="mb-1">Organ uchwałodawczy</Label>
                            <Input defaultValue={unit?.legislativeUnitName} {...register('legislativeUnitName')} />
                        </div>
                        <div className="space-y-2">
                            <Label className="mb-1">Organ wykonawczy</Label>
                            <Input defaultValue={unit?.executiveUnitName} {...register('executiveUnitName')} />
                        </div>
                        <div className="space-y-2">
                            <Label className="mb-1">Adres</Label>
                            <Input defaultValue={unit?.address} {...register('address')} />
                        </div>
                    </div>
                </div>
            </form>
        </DataRenderer>
    );
};

export default UnitEditView;
