import React from 'react';
import { useParams } from 'react-router';

import DataRenderer from '../../../shared/ui/data-renderer';
import { useGroupsForChapterQuery } from '../../Groups/data-access/queries/get-groups-for-chapter.query';
import { useGetChapterQuery } from '../data-access/queries/get-chapter.query';

const ChapterView = () => {
    const { id } = useParams();
    const { data: chapter, isLoading, isError } = useGetChapterQuery(id!);
    const { data: groups } = useGroupsForChapterQuery(id!);

    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <div className="px-4 sm:px-0">
                <h3 className="text-base font-semibold leading-7 text-gray-900">{chapter?.name}</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Dane rozdziału</p>
            </div>
            <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Nazwa</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{chapter?.name}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Typ</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">Ustawa</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Status</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {chapter?.isRequired ? 'Obowiązkowy' : 'Nieobowiązkowy'}
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Grupy</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <ul>{groups?.map((group) => <li key={group.idGroup}>{group.name}</li>)}</ul>
                        </dd>
                    </div>
                </dl>
            </div>
        </DataRenderer>
    );
};

export default ChapterView;
