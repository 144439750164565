import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useDocument from '../../../hooks/useDocument';
import ChapterElement from '../../Chapters/components/chapter-element.component';
import { Chapter } from '../../Chapters/data-access/models/chapter';
import { routes } from '../../../shared/utils/routes';
import DataRenderer from '../../../shared/ui/data-renderer';
import { Button } from '../../../shared/ui/ui';

function DocumentDetail() {
    const { isLoading, getChaptersForDocument } = useDocument();
    const [chapters, setChapters] = useState<Chapter[]>();
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (id) {
                const chapters = await getChaptersForDocument(Number(id));
                setChapters(chapters);
            }
        })();
    }, []);

    const handleGeneratePdf = () => {
        navigate({
            pathname: routes.docs.generatePdf?.url(id!),
        });
    };

    return (
        <DataRenderer isLoading={isLoading} error={false}>
            <div className="flex w-full flex-col">
                <div className="flex w-full justify-between">
                    <p className="text-base font-semibold leading-6 text-gray-900">Dane dokumentu</p>
                    <Button className="ml-auto w-36" onClick={handleGeneratePdf}>
                        Generuj dokument
                    </Button>
                </div>
                {chapters?.map((chapter) => {
                    return (
                        <div key={chapter.idChapter} className="w-full">
                            <ChapterElement chapter={chapter} />
                        </div>
                    );
                })}
            </div>
        </DataRenderer>
    );
}

export default DocumentDetail;
