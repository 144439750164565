import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import DataRenderer from '../../../shared/ui/data-renderer';
import { Header, TableContent } from '../../../shared/ui/Table/table-content';
import { routes } from '../../../shared/utils/routes';
import GroupListElement from '../components/group-list-element.component';
import { Group } from '../data-access/models/group';
import { useDeleteGroupMutation } from '../data-access/mutatuions/delete-group.mutation';
import { useGetGroupsQuery } from '../data-access/queries/get-all-groups.query';

const GroupList = () => {
    const navigate = useNavigate();
    const { mutateAsync: deleteGroup } = useDeleteGroupMutation();
    const { data: groups, isLoading, isError } = useGetGroupsQuery();

    const handleAddGroup = async () => {
        navigate({
            pathname: routes.group.add?.path,
        });
    };

    const handleEdit = (idGroup: number) => {
        navigate({
            pathname: routes.group.edit?.url(idGroup.toString()),
        });
    };

    const handleDelete = async (group: Group) => {
        try {
            await deleteGroup(group);
            toast.success('Grupa została pomyślnie usnuięta');
        } catch (e) {
            toast.error('Nie udało się usunąć grupy');
        }
    };
    const tableHeaders: Header[] = [{ id: 1, title: 'Nazwa' }];

    const sortedGroups: Group[] = useMemo(() => {
        return groups
            ? [...groups].sort((a, b) => new Date(b.updatedTime!).getTime() - new Date(a.updatedTime!).getTime())
            : [];
    }, [groups]);

    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <TableContent
                headingTitle="Lista grup"
                headingAddLabel="Dodaj nową grupę"
                onHeadingAdd={handleAddGroup}
                tableHeaders={tableHeaders}
                tableBodyContent={sortedGroups.map((group) => {
                    return (
                        <GroupListElement
                            key={group.idGroup}
                            group={group}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                    );
                })}
            />
        </DataRenderer>
    );
};
export default GroupList;
