import { toast } from 'sonner';

import { getHttpClient } from '../../../../shared/utils/http-client.ts';
import { Paragraph } from '../../../Paragraphs/data-access/models/paragraph.js';
import { getGroupParagraphEndpoint, getParagraphForGroupEndpoint } from './const';
import { GroupParagraph } from '../models/group-paragraph.js';

export const getGroupParagraphs = async (id: string): Promise<GroupParagraph[]> => {
    try {
        const response = await getHttpClient().get<GroupParagraph[]>({ url: `${getGroupParagraphEndpoint}` });

        if (response) {
            return response;
        }

        toast.error('Brak danych.');
        throw new Error('No paragraphs data received.');
    } catch (error) {
        toast.error(`Błąd podczas pobierania danych: ${error}`);
        throw error;
    }
};
