import { toast } from 'sonner';

import { getHttpClient } from '../../../../shared/utils/http-client.ts';
import { Paragraph } from '../../../Paragraphs/data-access/models/paragraph.js';
import { getParagraphForGroupEndpoint } from './const';

export const getParagraphForGroup = async (id: string): Promise<Paragraph[]> => {
    try {
        const response = await getHttpClient().get<Paragraph[]>({ url: `${getParagraphForGroupEndpoint}/${id}` });

        if (response) {
            return response;
        }

        toast.error('Brak danych.');
        throw new Error('No paragraphs data received.');
    } catch (error) {
        toast.error(`Błąd podczas pobierania danych: ${error}`);
        throw error;
    }
};
