import { useMutation } from 'react-query';

import { deleteGroup } from '../infrastructure/delete-group';
import { useInvalidateAllGroups } from './utils/invalidate-all-groups';

export const useDeleteGroupMutation = () => {
    const invalidateAll = useInvalidateAllGroups();

    return useMutation({
        mutationFn: deleteGroup,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
