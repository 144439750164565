import { MoreVertical } from 'lucide-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AlertDialog } from '../../../shared/ui/Modal/alert-dialog';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../../shared/ui/ui';
import { routes } from '../../../shared/utils/routes';
import { Document } from '../data-access/models/document';

type DocumentListElementProps = {
    document: Document;
    onDelete: (document: Document) => void;
};

const DocumentListElement = ({ document, onDelete }: DocumentListElementProps) => {
    const navigate = useNavigate();
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const handleChapterEdition = () => {
        navigate({
            pathname: routes.docs.edit?.url(document.idDocument.toString()),
        });
    };

    const handleConfirmDelete = () => {
        onDelete(document);
        setIsDeleteOpen(false);
    };

    const handlePreview = () => {
        navigate({
            pathname: routes.docs.details?.url(document.idDocument.toString()),
        });
    };

    return (
        <>
            <tr key={document.idDocument}>
                <td className="td-name">{document.name}</td>
                <td className="whitespace-wrap max-w-lg px-3 py-4 text-sm text-gray-500">{document.description}</td>
                <td className="flex h-12 justify-end">
                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <MoreVertical className="mr-1 h-4 w-4" />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuItem onClick={handlePreview}>Podgląd</DropdownMenuItem>
                            <DropdownMenuItem onClick={handleChapterEdition}>Edytuj</DropdownMenuItem>
                            <DropdownMenuItem onClick={() => setIsDeleteOpen(true)}>Usuń</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </td>
                <td />
            </tr>
            <AlertDialog
                type="Uwaga"
                title="Potwierdzenie Usunięcia"
                description="Czy na pewno chcesz usunąć ten dokument? Ta operacja jest nieodwracalna."
                isOpen={isDeleteOpen}
                onOpenChange={() => setIsDeleteOpen((prev) => !prev)}
                onConfirm={handleConfirmDelete}
            />
        </>
    );
};
export default DocumentListElement;
