import React from 'react';
import { Navigate, Routes } from 'react-router';
import { Route } from 'react-router-dom';

import { useAuth } from './shared/utils/auth';
import { routes } from './shared/utils/routes';
import { DefaultLayout } from './layouts/default-layout';
import LoginPage from './features/auth/containers/login-view.component.tsx';
import ChapterAddView from './features/Chapters/containers/chapter-add-view.component';
import ChapterEditView from './features/Chapters/containers/chapter-edit-view.component';
import ChapterList from './features/Chapters/containers/chapter-list-view.component';
import ChapterView from './features/Chapters/containers/chapter-view.component';
import CommentAddView from './features/Comments/containers/comment-add-view.component';
import CommentEditView from './features/Comments/containers/comment-edit-view.component';
import CommentList from './features/Comments/containers/comment-list-view.component';
import DocumentAddView from './features/Documents/containers/document-add-view.component';
import DocumentEdit from './features/Documents/containers/document-edit-view.component';
import DocumentList from './features/Documents/containers/document-list-view.component';
import DocumentView from './features/Documents/containers/document-view.component';
import GroupAddView from './features/Groups/containers/group-add-view.component';
import GroupEdit from './features/Groups/containers/group-edit-view.component';
import GroupList from './features/Groups/containers/group-list-view.component';
import JustificationAddView from './features/Justifications/containers/justification-add-view.component';
import JustificationList from './features/Justifications/containers/justification-list-view.component';
import { JustificationView } from './features/Justifications/containers/justification-view.component';
import JustificationEdit from './features/Justifications/containers/justyfication-edit-view.component';
import ParagraphAddView from './features/Paragraphs/containers/paragraph-add-view.component';
import ParagraphEdit from './features/Paragraphs/containers/paragraph-edit-view.component';
import ParagraphList from './features/Paragraphs/containers/paragraph-list-view.component';
import UnauthorizedLayout from './layouts/unauthorized-layout';
import DocumentDetail from './features/Documents/components/document-detail.component';
import DocumentPdfGenerator from './features/Documents/components/document-pdf-generator.component';
import UnitView from './features/Unit/containers/unit-view.component';
import UnitEditView from './features/Unit/containers/unit-edit-view.component';

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
    const { user, token } = useAuth();

    if (!user && !token) {
        return <Navigate to={routes.login.path} />;
    }
    return <DefaultLayout>{children}</DefaultLayout>;
};

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="*" element={<Navigate to={routes.login.path} replace />} />
            <Route
                path={routes.login.path}
                element={
                    <UnauthorizedLayout>
                        <LoginPage />
                    </UnauthorizedLayout>
                }
            />
            <Route
                path={routes.register.path}
                element={
                    <UnauthorizedLayout>
                        <LoginPage />
                    </UnauthorizedLayout>
                }
            />

            {/* Document Routes */}
            <Route
                path={routes.docs.path}
                element={
                    <PrivateRoute>
                        <DocumentList />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.docs.add?.path}
                element={
                    <PrivateRoute>
                        <DocumentAddView />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.docs.edit?.path}
                element={
                    <PrivateRoute>
                        <DocumentEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.docs.details?.path}
                element={
                    <PrivateRoute>
                        <DocumentView />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.docs.generate?.path}
                element={
                    <PrivateRoute>
                        {/* <DocumentPdfView /> */}
                        <DocumentDetail />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.docs.generatePdf?.path}
                element={
                    <PrivateRoute>
                        {/* <DocumentPdfView /> */}
                        <DocumentPdfGenerator />
                    </PrivateRoute>
                }
            />

            {/* Chapter Routes */}
            <Route
                path={routes.chapter.path}
                element={
                    <PrivateRoute>
                        <ChapterList />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.chapter.add?.path}
                element={
                    <PrivateRoute>
                        <ChapterAddView />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.chapter.edit?.path}
                element={
                    <PrivateRoute>
                        <ChapterEditView />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.chapter.details?.path}
                element={
                    <PrivateRoute>
                        <ChapterView />
                    </PrivateRoute>
                }
            />

            {/* Group Routes */}
            <Route
                path={routes.group.path}
                element={
                    <PrivateRoute>
                        <GroupList />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.group.add?.path}
                element={
                    <PrivateRoute>
                        <GroupAddView />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.group.edit?.path}
                element={
                    <PrivateRoute>
                        <GroupEdit />
                    </PrivateRoute>
                }
            />

            {/* Paragraph Routes */}
            <Route
                path={routes.paragraph.path}
                element={
                    <PrivateRoute>
                        <ParagraphList />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.paragraph.add?.path}
                element={
                    <PrivateRoute>
                        <ParagraphAddView />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.paragraph.edit?.path}
                element={
                    <PrivateRoute>
                        <ParagraphEdit />
                    </PrivateRoute>
                }
            />

            {/* Justification Routes */}
            <Route
                path={routes.justification.path}
                element={
                    <PrivateRoute>
                        <JustificationList />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.justification.add?.path}
                element={
                    <PrivateRoute>
                        <JustificationAddView />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.justification.edit?.path}
                element={
                    <PrivateRoute>
                        <JustificationEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.justification.details?.path}
                element={
                    <PrivateRoute>
                        <JustificationView />
                    </PrivateRoute>
                }
            />

            {/* Comment Routes */}
            <Route
                path={routes.comment.path}
                element={
                    <PrivateRoute>
                        <CommentList />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.comment.add?.path}
                element={
                    <PrivateRoute>
                        <CommentAddView />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.comment.edit?.path}
                element={
                    <PrivateRoute>
                        <CommentEditView />
                    </PrivateRoute>
                }
            />

            {/* Unit Routes */}
            <Route
                path={routes.unit.path}
                element={
                    <PrivateRoute>
                        <UnitView />
                    </PrivateRoute>
                }
            />
            <Route
                path={routes.unit.edit?.path}
                element={
                    <PrivateRoute>
                        <UnitEditView />
                    </PrivateRoute>
                }
            />

            {/* Act Routes */}
            {/* <Route
                path={routes.act.path}
                element={
                    <PrivateRoute>
                        <DocumentList />
                    </PrivateRoute>
                }
            /> */}
        </Routes>
    );
};

export default AppRoutes;
