import React from 'react';
import { useParams } from 'react-router';

import DataRenderer from '../../../shared/ui/data-renderer';
import TextEditor from '../../../shared/ui/TextEditor/TextEditor';
import { useGetJustificationQuery } from '../data-access/queries/get-justification.query';

export const JustificationView = () => {
    const { id } = useParams();
    const { data: justification, isLoading, isError } = useGetJustificationQuery(id!);
    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <div className="px-4 sm:px-0">
                <h3 className="text-base font-semibold leading-7 text-gray-900">{justification?.name}</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Dane uzasadnienia</p>
            </div>
            <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Nazwa</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {justification?.name}
                        </dd>
                    </div>
                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-0 sm:px-0">
                        <dt className="py-5 text-sm font-medium leading-6 text-gray-900">Treść</dt>
                        <dd className=" text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {justification?.text !== undefined && (
                                <TextEditor editorState={justification?.text} editable={false} />
                            )}
                        </dd>
                    </div>
                </dl>
            </div>
        </DataRenderer>
    );
};
