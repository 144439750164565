import { toast } from 'sonner';

import { getHttpClient } from '../../../../shared/utils/http-client.ts';
import { Justification } from '../models/justification';
import { endpointName } from './const';

export const getAllJustifications = async (): Promise<Justification[]> => {
    try {
        const response = await getHttpClient().get<Justification[]>({ url: endpointName });
        const justificationList: Justification[] = [];

        if (response) {
            response.forEach((just) => justificationList.push(just));
            return justificationList;
        }

        toast.error('Brak danych.');
        throw new Error('No justifications data received.');
    } catch (error) {
        toast.error(`Błąd podczas pobierania danych: ${error}`);
        throw error;
    }
};
