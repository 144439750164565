import { useQuery } from 'react-query';
import { z } from 'zod';

import { queryKey } from '../comments.query-keys';
import { getCommentById } from '../infrastructure/get-comment';

export const useGetCommentQuery = (commentId: string) => {
    return useQuery({
        queryKey: [queryKey.comments(), commentId],
        queryFn: () => getCommentById(commentId),
        onError(error) {
            if (error instanceof z.ZodError) {
                console.log('error from useGetCommentQuery', error);
            }
        },
    });
};
