import React from 'react';
import { Button } from '../../../shared/ui/ui';
import { Justification } from '../data-access/models/justification';

type JustificationModalElementProps = {
    justification: Justification;
    selectedComments: number;
    setSelectedComments: (id: number) => void;
    onAddHandler: (id: number) => void;
};

const JustificationModalElement = ({
    justification,
    selectedComments,
    onAddHandler,
}: JustificationModalElementProps) => {
    const buttonText = selectedComments === justification.idJustification ? 'Wybrany' : 'Wybierz';

    return (
        <tr>
            <td className="td-name">{justification.name}</td>
            <td>
                <Button
                    size="sm"
                    className="min-w-20 text-xs"
                    type="button"
                    variant={buttonText === 'Wybierz' ? 'outline' : 'default'}
                    onClick={() => onAddHandler(justification.idJustification)}
                >
                    {buttonText}
                </Button>
            </td>
        </tr>
    );
};

export default JustificationModalElement;
