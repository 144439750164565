import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import DataRenderer from '../../../shared/ui/data-renderer';
import { Header, TableContent } from '../../../shared/ui/Table/table-content';
import { routes } from '../../../shared/utils/routes';
import ParagraphListElement from '../components/paragraph-list-element.component';
import { Paragraph } from '../data-access/models/paragraph';
import { useDeleteParagraphMutation } from '../data-access/mutatuions/delete-paragraphs.mutation';
import { useGetParagraphsQuery } from '../data-access/queries/get-all-paragraphs.query';

const ParagraphList = () => {
    const navigate = useNavigate();

    const { data: paragraphs, isLoading } = useGetParagraphsQuery();
    const { mutateAsync: deleteParagraph } = useDeleteParagraphMutation();

    const handleAddParagraph = () => {
        navigate({
            pathname: routes.paragraph.add?.path,
        });
    };

    const handleEdit = (idParagraph: number) => {
        navigate({
            pathname: routes.paragraph.edit?.url(idParagraph.toString()),
        });
    };

    const handleDelete = async (paragraph: Paragraph) => {
        try {
            await deleteParagraph(paragraph);
            toast.success('Paragraf został pomyślnie usnuięty');
        } catch (error) {
            toast.error('Nie udało się usunąć paragrafu');
        }
    };

    const tableHeaders: Header[] = [{ id: 1, title: 'Nazwa' }];

    return (
        <DataRenderer isLoading={isLoading} error={false}>
            <TableContent
                headingTitle="Paragrafy"
                headingAddLabel="Dodaj nowy paragraf"
                onHeadingAdd={handleAddParagraph}
                tableHeaders={tableHeaders}
                tableBodyContent={paragraphs?.map((paragraph) => {
                    return (
                        <ParagraphListElement
                            paragraph={paragraph}
                            key={paragraph.idParagraph}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                    );
                })}
            />
        </DataRenderer>
    );
};

export default ParagraphList;
