import React, { useEffect, useMemo, useState } from 'react';
import { cn } from '../../../lib/utils';
import DataRenderer from '../../../shared/ui/data-renderer';
import { Header, TableContent } from '../../../shared/ui/Table/table-content';

import { Button, Dialog, DialogClose, DialogContent, Input, Label } from '../../../shared/ui/ui';
import { useGetJustificationsQuery } from '../data-access/queries/get-all-justifications.query';
import JustificationModalElement from './justification-add-modal-element.componenet';
import AddJustificationModal from './justification-add-modal.component';

type JustificationAddModalProps = {
    idJustification: number;
    isOpen: boolean;
    onToggle: (isOpen: boolean) => void;
    onSelectJustification: (id: number) => void;
};

const SelectJustificationModal = (props: JustificationAddModalProps) => {
    const [selectOpen, setSelectOpen] = useState(false);
    const [addJustificationOpen, setAddJustificationOpen] = useState(false);
    const { data: justifications, isLoading, isError } = useGetJustificationsQuery();
    const tableHeaders: Header[] = [{ id: 1, title: 'Nazwa' }];
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCommentIds, setSelectedCommentIds] = useState<number>(props.idJustification);

    useEffect(() => {
        setSelectedCommentIds(props.idJustification);
    }, [props.idJustification]);

    const handleJustificationAdd = () => {
        props.onSelectJustification(selectedCommentIds);
        props.onToggle(false);
    };

    const handleSelectJustification = (id: number) => {
        setSelectedCommentIds(id);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredAndSortedJustifications = useMemo(() => {
        const filteredJustifications = justifications
            ? justifications.filter((justification) =>
                  justification.name.toLowerCase().includes(searchTerm.toLowerCase()),
              )
            : [];

        return filteredJustifications.sort(
            (a, b) => new Date(b.updatedTime!).getTime() - new Date(a.updatedTime!).getTime(),
        );
    }, [justifications, searchTerm]);

    return (
        <>
            <Dialog open={props.isOpen} onOpenChange={props.onToggle}>
                <DialogContent
                    className={cn(
                        'w-full overflow-x-auto py-4 md:min-w-[900px] md:px-12 md:py-16 lg:min-w-[1200px] ',
                        !selectOpen ? 'h-screen md:h-[800px]' : '',
                    )}
                >
                    <DataRenderer isLoading={isLoading} error={isError}>
                        <TableContent
                            headingTitle="Lista uzasadnień"
                            headingAddLabel="Dodaj nowe uzasadnienie"
                            onHeadingAdd={() => setAddJustificationOpen(true)}
                            tableHeaders={tableHeaders}
                            childBeforeContent={
                                <div className="mt-6 flex flex-col gap-2">
                                    <Input placeholder="Wyszukaj uzasadnienie" onChange={handleSearch} />
                                </div>
                            }
                            tableBodyContent={
                                filteredAndSortedJustifications.length > 0 ? (
                                    filteredAndSortedJustifications?.map((justification) => (
                                        <JustificationModalElement
                                            key={justification.idJustification}
                                            justification={justification}
                                            selectedComments={selectedCommentIds}
                                            setSelectedComments={setSelectedCommentIds}
                                            onAddHandler={handleSelectJustification}
                                        />
                                    ))
                                ) : (
                                    <div className="flex h-[550px] w-full items-center justify-center text-black">
                                        <p>Brak uzasadnień</p>
                                    </div>
                                )
                            }
                        />
                    </DataRenderer>

                    {selectedCommentIds !== 0 && (
                        <DialogClose className="flex">
                            <Button onClick={handleJustificationAdd} className="ml-auto min-w-24">
                                Zapisz
                            </Button>
                        </DialogClose>
                    )}
                </DialogContent>
            </Dialog>
            <AddJustificationModal
                triggerIcon={<span />}
                isOpen={addJustificationOpen}
                onOpenChange={setAddJustificationOpen}
            />
        </>
    );
};

export default SelectJustificationModal;
