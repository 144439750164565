import React from 'react';
import { useNavigate, useParams } from 'react-router';

import DataRenderer from '../../../shared/ui/data-renderer';
import { Button } from '../../../shared/ui/ui';
import { routes } from '../../../shared/utils/routes';
import { useChaptersForDocumentQuery } from '../../Chapters/data-access/queries/get-chapters-for-document.query';
import { useGetDocumentQuery } from '../data-access/queries/get-document.query';

const DocumentView = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { data: chapters, isLoading, isError } = useChaptersForDocumentQuery(id!);
    const { data: document, isLoading: isLoadingDocs, isError: isErrorDocs } = useGetDocumentQuery(id!);

    const handleGeneratePdf = () => {
        navigate({
            pathname: routes.docs.generate?.url(id!),
        });
    };

    const chapterNames = chapters?.map((chapter) => chapter.name).join(', ');

    return (
        <DataRenderer isLoading={isLoading && isLoadingDocs} error={isError && isErrorDocs}>
            <div className="flex w-full justify-between px-4 sm:px-0">
                <div>
                    <h3 className="text-base font-semibold leading-7 text-gray-900">{document?.name}</h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Dane dokumentu</p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <Button onClick={handleGeneratePdf}>Generuj PDF</Button>
                </div>
            </div>
            <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Nazwa</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{document?.name}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Typ</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {document?.idDocumentType}
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Opis</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {document?.description === '' ? 'Brak opisu' : document?.description}
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Rozdziały</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{chapterNames}</dd>
                    </div>
                </dl>
            </div>
        </DataRenderer>
    );
};

export default DocumentView;
