import { useMutation } from 'react-query';

import { deleteComment } from '../infrastructure/delete-comments';
import { useInvalidateAllComments } from './utils/invalidate-all-comments';

export const useDeleteCommentMutation = () => {
    const invalidateAll = useInvalidateAllComments();

    return useMutation({
        mutationFn: deleteComment,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
