import { useQuery } from 'react-query';
import { z } from 'zod';

import { queryKey } from '../documents.query-keys';
import { getDocumentById } from '../infrastructure/get-document';

export const useGetDocumentQuery = (documentId: string) => {
    return useQuery({
        queryKey: [queryKey.documents(), documentId],
        queryFn: () => getDocumentById(documentId),
        onError(error) {
            if (error instanceof z.ZodError) {
                console.log('error from useGetDocumentQuery', error);
            }
        },
    });
};
