export const endpointName = 'Chapter';
export const getChaptersForDocumentEndpointName = 'Chapter/byDocumentId';
export const getGroupChaptersEndpointName = 'Group/byChapterId';
export const createChapterEndpoint = 'Chapter';
export const getChapterGroupEndpoint = 'ChapterGroup';
export const createChapterGroupEndpoint = 'ChapterGroup';
export const updateChapterGroupEndpoint = 'ChapterGroup/Update';
export const getChapterEndpoint = 'Chapter';
export const updateChapterEndpoint = 'Chapter/Update';
export const deleteChapterEndpoint = 'Chapter/Delete';
