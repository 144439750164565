import { useQuery } from 'react-query';
import { z } from 'zod';

import { getAllParagraphs } from '../infrastructure/get-all-paragraphs';
import { queryKey } from '../paragraphs.query-keys';

export const useGetParagraphsQuery = () => {
    return useQuery({
        queryKey: queryKey.paragraphs(),
        queryFn: getAllParagraphs,
        onError(error) {
            if (error instanceof z.ZodError) {
                console.log('error from useGetParagraphsQuery', error);
            }
        },
    });
};
