import { toast } from 'sonner';

import { getHttpClient } from '../../../../shared/utils/http-client.ts';
import { endpointName, getDocumentChapterEndpoint } from './const';
import { DocumentChapterGroup } from '../models/document-chapter-group.js';

export const getDocumentChapterGroupDocuments = async (id: string): Promise<DocumentChapterGroup[]> => {
    try {
        const response = await getHttpClient().get<DocumentChapterGroup[]>({ url: `${getDocumentChapterEndpoint}` });
        const documentList: DocumentChapterGroup[] = [];

        if (response) {
            response.forEach((just) => documentList.push(just));
            return response;
        }

        toast.error('Brak danych.');
        throw new Error('No documents data received.');
    } catch (error) {
        toast.error(`Błąd podczas pobierania danych: ${error}`);
        throw error;
    }
};
