import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import useDocument from '../../../hooks/useDocument';
import TextEditor from '../../../shared/ui/TextEditor/TextEditor';
import { Group } from '../../Groups/data-access/models/group';
import { Paragraph } from '../../Paragraphs/data-access/models/paragraph';
import { Chapter } from '../data-access/models/chapter';
import DataRenderer from '../../../shared/ui/data-renderer';
import { useGetParagraphsForGroupQuery } from '../../Groups/data-access/queries/get-paragraph-for-groups.query';
import { useGroupsForChapterQuery } from '../../Groups/data-access/queries/get-groups-for-chapter.query';

type ChapterElementProps = {
    chapter: Chapter;
    chosenChapters: Chapter[];
    setChosenChapter: Dispatch<SetStateAction<Chapter[]>>;
    chosenGroups: Group[];
    setChosenGroups: Dispatch<SetStateAction<Group[]>>;
    chosenParagraphs: Paragraph[];
    setChosenParagraphs: Dispatch<SetStateAction<Paragraph[]>>;
};

type GroupElementProps = {
    group: Group;
    chosenGroups: Group[];
    setChosenGroups: Dispatch<SetStateAction<Group[]>>;
    chosenParagraphs: Paragraph[];
    setChosenParagraphs: Dispatch<SetStateAction<Paragraph[]>>;
    isRequired: boolean;
};

type ParagraphsElementProps = {
    paragraph: Paragraph;
    chosenParagraphs: Paragraph[];
    setChosenParagraphs: Dispatch<SetStateAction<Paragraph[]>>;
    isRequired: boolean;
};

function ParagraphsElementForGenerator({
    paragraph,
    chosenParagraphs,
    setChosenParagraphs,
    isRequired,
}: ParagraphsElementProps) {
    const [showDetails, setShowDetails] = useState(false);

    const isChecked = chosenParagraphs.some((p) => p.idParagraph === paragraph.idParagraph);

    const handleParagraphChange = (checked: boolean) => {
        if (checked) {
            setChosenParagraphs((prev) => [...prev, paragraph]);
        } else {
            setChosenParagraphs((prev) => prev.filter((p) => p.idParagraph !== paragraph.idParagraph));
        }
    };

    return (
        <div className="flex w-full flex-col justify-between pl-4 sm:pl-8">
            <div className="flex w-full justify-between">
                <div className="flex flex-col gap-2">
                    <p key={paragraph.idParagraph} className="cursor-pointer p-0">
                        {paragraph.name}
                    </p>
                    <div
                        className="cursor-pointer text-xs font-normal text-gray-600"
                        onClick={() => setShowDetails((prev) => !prev)}
                    >
                        {showDetails ? '(zwiń)' : '(pokaż więcej)'}
                    </div>
                </div>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={(e) => handleParagraphChange(e.target.checked)}
                        disabled={isRequired}
                    />
                    <span className="slider"></span>
                </label>
            </div>
            {showDetails && <TextEditor key={paragraph.idParagraph} editorState={paragraph.text} editable={false} />}
        </div>
    );
}

function GroupElementForGenerator({
    group,
    chosenGroups,
    chosenParagraphs,
    setChosenGroups,
    setChosenParagraphs,
    isRequired,
    onGroupChecked,
}: GroupElementProps & { onGroupChecked: (checked: boolean) => void }) {
    const { data: paragraphs } = useGetParagraphsForGroupQuery(group.idGroup.toString());

    const [showDetails, setShowDetails] = useState(false);

    const isChecked = chosenGroups.some((g) => g.idGroup === group.idGroup);

    const handleGroupCheckboxChange = (checked: boolean) => {
        if (checked) {
            setChosenGroups((prev) => [...prev, group]);
            if (paragraphs) {
                setChosenParagraphs((prev) => [...prev, ...paragraphs]);
            }
        } else {
            setChosenGroups((prev) => prev.filter((g) => g.idGroup !== group.idGroup));
            if (paragraphs) {
                setChosenParagraphs((prev) =>
                    prev.filter((p) => !paragraphs.some((par) => par.idParagraph === p.idParagraph)),
                );
            }
        }

        onGroupChecked(checked);
    };

    return (
        <div className="ml-2 sm:ml-4">
            <div className="flex w-full justify-between">
                <div className="flex flex-col gap-2">
                    <p key={group.idGroup}>{group.name}</p>
                    <div
                        className="cursor-pointer text-xs font-normal text-gray-600"
                        onClick={() => setShowDetails((prev) => !prev)}
                    >
                        {showDetails ? '(zwiń)' : '(pokaż więcej)'}
                    </div>
                </div>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={(e) => handleGroupCheckboxChange(e.target.checked)}
                        disabled={false}
                    />
                    <span className="slider"></span>
                </label>
            </div>

            {showDetails && paragraphs && (
                <div className="duration-400 my-4 ml-4 space-y-6 border-l border-t py-4 transition-opacity ease-in-out sm:ml-12">
                    {paragraphs.map((paragraph) => (
                        <ParagraphsElementForGenerator
                            key={paragraph.idParagraph}
                            paragraph={paragraph}
                            chosenParagraphs={chosenParagraphs}
                            setChosenParagraphs={setChosenParagraphs}
                            isRequired={isChecked}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

function ChapterElementForGenerator({
    chapter,
    chosenChapters,
    chosenGroups,
    chosenParagraphs,
    setChosenChapter,
    setChosenGroups,
    setChosenParagraphs,
}: ChapterElementProps) {
    const { data: groups, isLoading } = useGroupsForChapterQuery(chapter.idChapter.toString());
    const [isChecked, setIsChecked] = useState(chapter.isRequired);

    const isRequired = chapter.isRequired;

    useEffect(() => {
        if (isRequired && !chosenChapters.includes(chapter)) {
            setChosenChapter((prevState) => [...prevState, chapter]);
        }
    }, [isRequired, chapter, chosenChapters, setChosenChapter]);

    const handleChapterCheckboxChange = (isChecked: boolean) => {
        setIsChecked(isChecked);

        if (isChecked) {
            setChosenChapter((prevState) => [...prevState, chapter]);
        } else {
            setChosenChapter((prevState) => prevState.filter((ch) => ch.idChapter !== chapter.idChapter));
        }
    };

    const handleGroupChecked = (isGroupChecked: boolean) => {
        if (isGroupChecked && !isChecked) {
            setIsChecked(true);
            setChosenChapter((prevState) => [...prevState, chapter]);
        } else if (!isGroupChecked) {
            const remainingCheckedGroups = groups?.filter((group) =>
                chosenGroups.some((g) => g.idGroup === group.idGroup),
            );

            if (remainingCheckedGroups?.length === 0) {
                setIsChecked(false);
                setChosenChapter((prevState) => prevState.filter((ch) => ch.idChapter !== chapter.idChapter));
            }
        }
    };

    return (
        <DataRenderer isLoading={isLoading} error={false}>
            <div className="whitespace-wrap w-full py-4 pl-0 pr-0 text-sm font-medium text-gray-900 sm:pl-0 md:min-w-48 md:pl-0 md:pr-3">
                <div className="flex w-full justify-between">
                    <div>
                        <p className="text-base">{chapter.name}</p>
                    </div>
                    <label className="switch">
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={(e) => handleChapterCheckboxChange(e.target.checked)}
                            disabled={chapter.isRequired && isChecked}
                        />
                        <span className="slider"></span>
                    </label>
                </div>

                {groups && (
                    <div className="mt-4 space-y-2 border-l sm:ml-2">
                        <p className="ml-4 p-0 text-base">Grupy:</p>
                        <div className="duration-400 ml-4 space-y-6 border-l border-t py-4 transition-opacity ease-in-out sm:my-4 sm:ml-12">
                            {groups.map((group) => (
                                <GroupElementForGenerator
                                    key={group.idGroup}
                                    group={group}
                                    chosenGroups={chosenGroups}
                                    setChosenGroups={setChosenGroups}
                                    chosenParagraphs={chosenParagraphs}
                                    setChosenParagraphs={setChosenParagraphs}
                                    isRequired={isRequired}
                                    onGroupChecked={handleGroupChecked}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </DataRenderer>
    );
}

export default ChapterElementForGenerator;
