import { toast } from 'sonner';

import { getHttpClient } from '../../../../shared/utils/http-client.ts';
import { getUnitByIdEndpoint } from './const';
import { Unit } from '../models/unit';

export const getUnitById = async (id: string): Promise<Unit> => {
    try {
        const response = await getHttpClient().get<Unit>({ url: `${getUnitByIdEndpoint}/${id}` });

        if (response) {
            return response;
        }

        toast.error('Brak danych.');
        throw new Error('No paragraphs data received.');
    } catch (error) {
        toast.error(`Błąd podczas pobierania danych: ${error}`);
        throw error;
    }
};
