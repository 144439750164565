type SubRouteStatic = {
    path: string;
};

type SubRouteDynamic = {
    path: string;
    url: (id: string) => string;
};

type RouteSegment = {
    name: string;
    path: string;
    add?: SubRouteStatic;
    edit?: SubRouteDynamic;
    details?: SubRouteDynamic;
    generate?: SubRouteDynamic;
    generatePdf?: SubRouteDynamic;
};

export type RoutesType = {
    [key: string]: RouteSegment;
};

export const routes: RoutesType = {
    login: {
        name: 'Logowanie',
        path: '/login',
    },
    register: {
        name: 'Rejestracja',
        path: '/register',
    },
    docs: {
        name: 'Dokumenty',
        path: '/docs',
        add: {
            path: '/docs/add',
        },
        edit: {
            path: '/docs/edit/:id',
            url: (id: string) => '/docs/edit/:id'.replace(':id', id),
        },
        details: {
            path: '/docs/:id',
            url: (id: string) => '/docs/:id'.replace(':id', id),
        },
        generate: {
            path: '/docs-generate/:id',
            url: (id: string) => '/docs-generate/:id'.replace(':id', id),
        },
        generatePdf: {
            path: '/docs-generate-pdf/:id',
            url: (id: string) => '/docs-generate-pdf/:id'.replace(':id', id),
        },
    },
    chapter: {
        name: 'Rozdziały',
        path: '/chapter',
        add: {
            path: '/chapter/add',
        },
        edit: {
            path: '/chapter/edit/:id',
            url: (id: string) => '/chapter/edit/:id'.replace(':id', id),
        },
        details: {
            path: '/chapter/:id',
            url: (id: string) => '/chapter/:id'.replace(':id', id),
        },
    },
    group: {
        name: 'Grupy',
        path: '/group',
        add: {
            path: '/group/add',
        },
        edit: {
            path: '/group/edit/:id',
            url: (id: string) => '/group/edit/:id'.replace(':id', id),
        },
    },
    paragraph: {
        name: 'Paragrafy',
        path: '/paragraph',
        add: {
            path: '/paragraph/add',
        },
        edit: {
            path: '/paragraph/edit/:id',
            url: (id: string) => '/paragraph/edit/:id'.replace(':id', id),
        },
    },
    justification: {
        name: 'Uzasadnienia',
        path: '/justification',
        add: {
            path: '/justification/add',
        },
        edit: {
            path: '/justification/edit/:id',
            url: (id: string) => '/justification/edit/:id'.replace(':id', id),
        },
        details: {
            path: '/justification/:id',
            url: (id: string) => '/justification/:id'.replace(':id', id),
        },
    },
    comment: {
        name: 'Komentarze',
        path: '/comment',
        add: {
            path: '/comment/add',
        },
        edit: {
            path: '/comment/edit/:id',
            url: (id: string) => '/comment/edit/:id'.replace(':id', id),
        },
    },
    unit: {
        name: 'Dane jednostki',
        path: '/unit',
        add: {
            path: '/unit/add',
        },
        edit: {
            path: '/unit/edit/:id',
            url: (id: string) => '/unit/edit/:id'.replace(':id', id),
        },
    },
    // act: {
    //     name: 'Baza aktów',
    //     path: '/act',
    //     add: {
    //         path: '/act/add',
    //     },
    //     edit: {
    //         path: '/act/edit/:id',
    //         url: (id: string) => '/act/edit/:id'.replace(':id', id),
    //     },
    // },
};
