import React from 'react';

import SideBar from './components/desktop-sidebar';
import Navbar from './components/NavBar';

const DefaultLayout = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className="relative">
            <Navbar />
            <SideBar />
            <div className="pt-16 lg:pl-72">
                <div className="p-4">{children}</div>
            </div>
        </div>
    );
};

export default DefaultLayout;
