import { toast } from 'sonner';

import { getHttpClient } from '../../../../shared/utils/http-client.ts';
import { getChapterGroupEndpoint } from './const';
import { ChapterGroup } from '../models/chapter-group';

export const getChaptersGroupParagraphs = async (id: string): Promise<ChapterGroup[]> => {
    try {
        const response = await getHttpClient().get<ChapterGroup[]>({ url: `${getChapterGroupEndpoint}` });
        const chapterGroupList: ChapterGroup[] = [];

        if (response) {
            response.forEach((group) => chapterGroupList.push(group));
            return chapterGroupList;
        }

        toast.error('Brak danych.');
        throw new Error('No chapters data received.');
    } catch (error) {
        toast.error(`Błąd podczas pobierania danych: ${error}`);
        throw error;
    }
};
