import React, { useEffect, useState } from 'react';
import { Reorder } from 'framer-motion';
import { Button, Input, Label } from './ui';

type ItemSelectorProps<T> = {
    allItemsQuery: T[];
    assignedItemsQuery?: T[] | T;
    itemType: string;
    itemKey: keyof T;
    itemNameKey: keyof T;
    selectedFromAllItems: number[];
    setSelectedFromAllItems: React.Dispatch<React.SetStateAction<number[]>>;
    selectedFromUsedItems: number[];
    setSelectedFromUsedItems: React.Dispatch<React.SetStateAction<number[]>>;
    onSelectedItemsChange?: (selectedItems: T[]) => void;
    renderListItem: (
        item: T,
        selected: number[],
        setSelected: React.Dispatch<React.SetStateAction<number[]>>,
    ) => JSX.Element;
};

const ItemSelector = <T,>({
    allItemsQuery,
    assignedItemsQuery = [],
    itemType,
    itemKey,
    itemNameKey,
    selectedFromAllItems,
    setSelectedFromAllItems,
    selectedFromUsedItems,
    setSelectedFromUsedItems,
    onSelectedItemsChange,
    renderListItem,
}: ItemSelectorProps<T>) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [items, setItems] = useState<T[]>(() =>
        Array.isArray(assignedItemsQuery) ? assignedItemsQuery : [assignedItemsQuery],
    );
    const [allItems, setAllItems] = useState<T[]>(() => {
        return allItemsQuery.filter(
            (item) =>
                !Array.isArray(assignedItemsQuery) ||
                !assignedItemsQuery.some((assignedItem) => item[itemKey] === assignedItem[itemKey]),
        );
    });

    useEffect(() => {
        const processedAssignedItems = Array.isArray(assignedItemsQuery) ? assignedItemsQuery : [assignedItemsQuery];
        setItems(processedAssignedItems);
        const newAllItems = allItemsQuery.filter(
            (item) => !processedAssignedItems.some((assignedItem) => item[itemKey] === assignedItem[itemKey]),
        );
        setAllItems(newAllItems);
    }, [allItemsQuery, assignedItemsQuery]);

    useEffect(() => {
        if (onSelectedItemsChange) {
            onSelectedItemsChange(items);
        }
    }, [items, onSelectedItemsChange]);

    const onClickArrowLeftHandler = () => {
        const tmp = allItems.filter((el) => selectedFromAllItems.includes(el[itemKey] as unknown as number));
        if (tmp.length) {
            const newItems = [...items, ...tmp];
            setItems(newItems);
            setAllItems((prevState) => prevState.filter((el) => !tmp.includes(el)));
        }
    };

    const onClickArrowUpHandler = () => {
        setItems((prevState) => {
            const newState = [...prevState];
            for (let i = 1; i < newState.length; i++) {
                if (selectedFromUsedItems.includes(newState[i][itemKey] as unknown as number)) {
                    [newState[i], newState[i - 1]] = [newState[i - 1], newState[i]];
                }
            }
            return newState;
        });
    };

    const onClickArrowDownHandler = () => {
        setItems((prevState) => {
            const newState = [...prevState];
            for (let i = newState.length - 2; i >= 0; i--) {
                if (selectedFromUsedItems.includes(newState[i][itemKey] as unknown as number)) {
                    [newState[i], newState[i + 1]] = [newState[i + 1], newState[i]];
                }
            }
            return newState;
        });
    };

    const onClickArrowRightHandler = () => {
        const tmp = items.filter((el) => selectedFromUsedItems.includes(el[itemKey] as unknown as number));
        if (tmp.length) {
            const remainingItems = items.filter((el) => !tmp.includes(el));
            setAllItems((prevState) => [...prevState, ...tmp]);
            setItems(remainingItems);
        }
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredItems = allItems.filter((item) =>
        (item[itemNameKey] as string).toString().toLowerCase().includes(searchTerm),
    );

    return (
        <div className="relative">
            <div className="sticky top-16 w-full bg-white p-4">
                <div className="flex w-full flex-col justify-between gap-2 sm:flex-row">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Wybierz {itemType}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Wybierz elementy do {itemType}.</p>
                    </div>
                </div>
                <div className="my-4 flex justify-between gap-8 ">
                    <div className="w-full max-w-xl">
                        <Input placeholder={`Wyszukaj ${itemType}`} className="w-full" onChange={handleSearch} />
                    </div>
                    <div className="flex flex-col gap-2 md:flex-row">
                        <Button type="button" onClick={onClickArrowLeftHandler}>
                            Dodaj {itemType}
                        </Button>
                        <Button type="button" onClick={onClickArrowUpHandler} variant="outline">
                            Przenieś w górę
                        </Button>
                        <Button type="button" onClick={onClickArrowDownHandler} variant="outline">
                            Przenieś w dół
                        </Button>
                        <Button type="button" onClick={onClickArrowRightHandler} variant="outline">
                            Usuń {itemType}
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mt-4 flex w-full flex-col rounded-md shadow-sm sm:col-span-6">
                <div className="flex w-full flex-col gap-4 sm:flex-row">
                    <div className="order-2 w-full sm:order-1">
                        <Label>Dostępne {itemType}</Label>
                        <div className="mt-2 flex flex-col flex-wrap gap-4">
                            {filteredItems.map((item) =>
                                renderListItem(item, selectedFromAllItems, setSelectedFromAllItems),
                            )}
                        </div>
                    </div>
                    <div className="order-1 w-full space-y-2">
                        <Label className="pb-2">Wybrane {itemType}</Label>
                        <Reorder.Group
                            className="flex flex-col gap-4"
                            values={items}
                            onReorder={(newOrder) => {
                                setItems(newOrder);
                            }}
                        >
                            {items.map((item) => (
                                <Reorder.Item key={item[itemKey] as unknown as number} value={item}>
                                    {renderListItem(item, selectedFromUsedItems, setSelectedFromUsedItems)}
                                </Reorder.Item>
                            ))}
                        </Reorder.Group>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ItemSelector;
