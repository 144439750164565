import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'sonner';

import TextEditor from '../../../shared/ui/TextEditor/TextEditor';
import { Button, Input, Label } from '../../../shared/ui/ui';
import { routes } from '../../../shared/utils/routes';
import { JustificationhFormValues } from '../components/justification-form.schema';
import { useAddJustificationMutation } from '../data-access/mutatuions/add-justifications.mutation';

const JustificationAddView = () => {
    const navigate = useNavigate();
    const [text, setText] = useState<string>('');

    const { mutateAsync: addJustification } = useAddJustificationMutation();
    const { register, handleSubmit } = useForm<JustificationhFormValues>();

    async function onSubmit(data: JustificationhFormValues) {
        const updatedData = {
            ...data,
            text: text,
        };
        try {
            await addJustification(updatedData);
            toast.success('Uzasadnienie zostało dodane.');
            navigate({ pathname: routes.justification.path });
        } catch (error) {
            toast.error(`Błąd przy dodawaniu uzasadnienia.`);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4 flex items-center justify-between">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Dane uzasadnienia</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">Wypełnij dane uzasadnienia.</p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <Button type="submit">Zapisz</Button>
                </div>
            </div>
            <div className="whitespace-wrap w-full py-4 pl-0 pr-0 text-sm text-gray-900 sm:pl-0 md:pl-0 md:pr-3">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <Label className="mb-1">Nazwa</Label>
                        <Input {...register('name')} />
                    </div>
                    <div>
                        <TextEditor editorState={text} editable={true} setEditorState={setText}></TextEditor>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default JustificationAddView;
