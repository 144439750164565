import { toast } from 'sonner';
import { z } from 'zod';

import HttpClient from '../../../../api';
import { getHttpClient } from '../../../../shared/utils/http-client.ts';
import { Comment, Comments } from '../models/comment';
import { endpointName } from './const';

const UserSchema = z.object({
    idUser: z.number(),
    name: z.string(),
    surname: z.string(),
    email: z.string(),
    password: z.string().optional(),
    role: z.number(),
});

const commentSchema = z.object({
    idComment: z.number(),
    idUser: z.number(),
    isDeleted: z.boolean(),
    paragraphComments: z.array(z.any()),
    user: UserSchema.optional().nullable(),
    value: z.string(),
});

const commentsSchema = z.array(commentSchema);

export const getAllComments = async (): Promise<Comment[]> => {
    try {
        const response = await getHttpClient().get<Comments[]>({ url: endpointName });
        const commentList: Comment[] = [];

        if (response) {
            response.forEach((comment) =>
                commentList.push({
                    ...comment,
                    order: 0,
                    idMarker: 0,
                }),
            );
            return commentList;
        }

        toast.error('Brak danych.');
        throw new Error('No comments data received.');
    } catch (error) {
        toast.error(`Błąd podczas pobierania danych: ${error}`);
        throw error;
    }
};
