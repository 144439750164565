import { useQuery } from 'react-query';
import { z } from 'zod';

import { queryKey } from '../chapters.query-keys';
import { getAllChapters } from '../queries/get-all-chapters.query';

export const useGetChaptersQuery = () => {
    return useQuery({
        queryKey: queryKey.chapters(),
        queryFn: getAllChapters,
        onError(error) {
            if (error instanceof z.ZodError) {
                console.log('error from useGetChaptersQuery', error);
            }
        },
    });
};
