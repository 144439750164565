import React, { useEffect, useState } from 'react';
import { MoreVertical } from 'lucide-react';

import DataRenderer from '../../../shared/ui/data-renderer';
import { AlertDialog } from '../../../shared/ui/Modal/alert-dialog';
import TextEditor from '../../../shared/ui/TextEditor/TextEditor';
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '../../../shared/ui/ui';
import { useGetJustificationQuery } from '../../Justifications/data-access/queries/get-justification.query';
import { Paragraph } from '../data-access/models/paragraph';

type ParagraphListElementProps = {
    paragraph: Paragraph;
    onEdit: (id: number) => void;
    onDelete: (paragraf: Paragraph) => void;
};
const ParagraphListElement = ({ paragraph, onEdit, onDelete }: ParagraphListElementProps) => {
    const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const {
        data: justification,
        isLoading,
        isError,
        refetch,
    } = useGetJustificationQuery(
        paragraph.idJustification?.toString() ?? '0',
        isCollapsibleOpen && paragraph.idJustification !== null,
    );

    const handleEdit = () => {
        onEdit(paragraph.idParagraph);
    };
    const handleDelete = () => {
        onDelete(paragraph);
        setIsDeleteOpen(false);
    };

    useEffect(() => {
        if (isCollapsibleOpen && paragraph.idJustification) {
            refetch();
        }
    }, [isCollapsibleOpen, paragraph.idJustification, refetch]);

    return (
        <>
            <tr>
                <td className="whitespace-wrap  py-4 pl-0 pr-0 text-sm font-medium text-gray-900 sm:pl-0 md:min-w-48 md:pl-0 md:pr-3">
                    <Collapsible open={isCollapsibleOpen} onOpenChange={setIsCollapsibleOpen}>
                        <CollapsibleTrigger asChild>
                            <p className="cursor-pointer p-0 underline">{paragraph.name}</p>
                        </CollapsibleTrigger>
                        <DataRenderer isLoading={isLoading} error={isError}>
                            <CollapsibleContent className="duration-400 transition-opacity ease-in-out">
                                <TextEditor editorState={paragraph.text} editable={false} />
                                {justification && (
                                    <div className="mt-4 space-y-2">
                                        <p>Uzasadnienie:</p>
                                        <p className="text-xs text-gray-700">{justification.name}</p>
                                        {/* <TextEditor editorState={justification?.text} editable={false} /> */}
                                    </div>
                                )}
                            </CollapsibleContent>
                        </DataRenderer>
                    </Collapsible>
                </td>
                <td>{paragraph.tags}</td>
                <td className="flex items-center justify-end py-4">
                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <MoreVertical className="mr-1 h-4 w-4" />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuItem onClick={handleEdit}>Edytuj</DropdownMenuItem>
                            <DropdownMenuItem onClick={() => setIsDeleteOpen(true)}>Usuń</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </td>
            </tr>

            <AlertDialog
                type="Uwaga"
                title="Potwierdzenie Usunięcia"
                description="Czy na pewno chcesz usunąć ten paragraf? Ta operacja jest nieodwracalna."
                isOpen={isDeleteOpen}
                onOpenChange={() => setIsDeleteOpen((prev) => !prev)}
                onConfirm={handleDelete}
            />
        </>
    );
};

export default ParagraphListElement;
