import { useQuery } from 'react-query';
import { z } from 'zod';

import { queryKey } from '../groups.query-keys';
import { getParagraphForGroup } from '../infrastructure/get-paragrapgh-for-group';

export const useGetParagraphsForGroupQuery = (groupId: string, isEnabled = true) => {
    return useQuery({
        queryKey: [queryKey.groups(), groupId],
        queryFn: () => getParagraphForGroup(groupId),
        onError(error) {
            if (error instanceof z.ZodError) {
                console.log('error from useGetParagraphsForGroupQuery', error);
            }
        },
        enabled: isEnabled,
    });
};
