import { toast } from 'sonner';

import { getHttpClient } from '../../../../shared/utils/http-client.ts';
import { Document } from '../models/document.js';
import { getDocumentEndpoint } from './const';

export const getDocumentById = async (id: string): Promise<Document> => {
    try {
        const response = await getHttpClient().get<Document>({ url: `${getDocumentEndpoint}/${id}` });

        if (response) {
            return response;
        }

        toast.error('Brak danych.');
        throw new Error('No document data received.');
    } catch (error) {
        toast.error(`Błąd podczas pobierania danych: ${error}`);
        throw error;
    }
};
