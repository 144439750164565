import { toast } from 'sonner';

import { getHttpClient } from '../../../../shared/utils/http-client.ts';
import { Chapter } from '../models/chapter';
import { getChapterEndpoint } from './const';

export const getChapterById = async (id: string): Promise<Chapter> => {
    try {
        const response = await getHttpClient().get<Chapter>({ url: `${getChapterEndpoint}/${id}` });

        if (response) {
            return response;
        }

        toast.error('Brak danych.');
        throw new Error('No chapter data received.');
    } catch (error) {
        toast.error(`Błąd podczas pobierania danych: ${error}`);
        throw error;
    }
};
