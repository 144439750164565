import React, { useEffect, useState } from 'react';

import useDocument from '../../../hooks/useDocument';
import TextEditor from '../../../shared/ui/TextEditor/TextEditor';
import { Group } from '../../Groups/data-access/models/group';
import { Paragraph } from '../../Paragraphs/data-access/models/paragraph';
import { Chapter } from '../data-access/models/chapter';
import { useGroupsForChapterQuery } from '../../Groups/data-access/queries/get-groups-for-chapter.query';
import DataRenderer from '../../../shared/ui/data-renderer';

type ChapterElementProps = {
    chapter: Chapter;
};

type GroupElementProps = {
    group: Group;
};

const GroupElement = ({ group }: GroupElementProps) => {
    const { getParagraphsForGroup } = useDocument();
    const [paragraphs, setParagraphs] = useState<Paragraph[]>([]);

    useEffect(() => {
        (async () => {
            const paragraphs = await getParagraphsForGroup(group.idGroup);
            setParagraphs(paragraphs);
        })();
    }, []);

    return (
        <tr>
            <td className="">
                <p className="ml-4 p-0 text-base sm:ml-8 ">{group.name}</p>

                {paragraphs.map((paragraph) => {
                    return (
                        <div key={paragraph.idParagraph} className="my-4 ml-4 space-y-6 border-l py-4 sm:ml-8">
                            <p className="ml-4 p-0 sm:ml-12">{paragraph.name}</p>

                            <div className="my-4 ml-4 space-y-6 border-l border-t px-2 py-4 sm:ml-12 sm:px-8">
                                <TextEditor editorState={paragraph.text} editable={false}></TextEditor>
                            </div>
                        </div>
                    );
                })}
            </td>
        </tr>
    );
};

const ChapterElement = ({ chapter }: ChapterElementProps) => {
    const { data: chapterGroups, isLoading, isError } = useGroupsForChapterQuery(chapter.idChapter.toString());

    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <tr>
                <td className="whitespace-wrap  py-4 pl-0 pr-0 text-base font-medium text-gray-900 sm:pl-0 md:min-w-48 md:pl-0 md:pr-3">
                    <p className="p-0 text-base sm:ml-4 ">
                        Rodział {chapter.order + 1} {chapter.name}
                    </p>

                    {chapterGroups?.map((group) => {
                        return (
                            <div key={group.idGroup} className="my-4 space-y-6 border-l border-t py-4 sm:ml-4">
                                <GroupElement group={group} />
                            </div>
                        );
                    })}
                </td>
            </tr>
        </DataRenderer>
    );
};

export default ChapterElement;
