import { useQuery } from 'react-query';
import { z } from 'zod';

import { queryKey } from '../chapters.query-keys';
import { getChaptersForDocument } from '../infrastructure/get-chapters-for-document';

export const useChaptersForDocumentQuery = (documentId: string) => {
    return useQuery({
        queryKey: [queryKey.chapters(), documentId],
        queryFn: () => getChaptersForDocument(documentId),
        onError(error) {
            if (error instanceof z.ZodError) {
                console.log('error from useChaptersForDocumentQuery', error);
            }
        },
    });
};
