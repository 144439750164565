import React, { useEffect, useRef, useState } from 'react';

import useDocument from '../../../hooks/useDocument';
import TextEditor from '../../../shared/ui/TextEditor/TextEditor';
import ChapterElementForGenerator from '../../Chapters/components/chapter-element-generator.component';
import { Chapter } from '../../Chapters/data-access/models/chapter';
import { Comment } from '../../Comments/data-access/models/comment';
import { Group } from '../../Groups/data-access/models/group';
import { Paragraph } from '../../Paragraphs/data-access/models/paragraph';
import { useParams } from 'react-router';
import { Button, Dialog, DialogContent, Input } from '../../../shared/ui/ui';
import { Unit } from '../../Unit/data-access/models/unit';
import useApi from '../../../hooks/useApi';
import { useGetUnitQuery } from '../../Unit/data-access/queries/get-unit.query';

interface PdfViewProps {
    text: string;
}
function PdfView(props: PdfViewProps) {
    //const [text, setText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [html, setHtml] = useState('');
    useEffect(() => {
        // const indexes = [1, 1, 1, 1, 1, 1];
        // const counter = (index: number) => {
        //     return `§ ${indexes[index]++}. `;
        // };
        //
        // let textTmp: any = null;
        // props.paragraphs.map((paragraph) => {
        //     if (!textTmp) {
        //         textTmp = JSON.parse(paragraph.text);
        //     } else {
        //         JSON.parse(paragraph.text).root.children.map((el: any) => {
        //             textTmp.root.children.push(el);
        //         });
        //     }
        // });
        // let textWithData = JSON.stringify(textTmp);
        // for (let i = 1; i <= 6; i++) {
        //     const stringToReplace = '{{§' + i + '}}';
        //     const regex = new RegExp(stringToReplace, 'g');
        //     textWithData = textWithData.replace(regex, () => {
        //         return counter(i);
        //     });
        // }
        // setText(textWithData);
        // setIsLoading(false);
    }, []);

    function download(blob: any, filename: any) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    const onGeneratePdfClick = async () => {
        const pdf = {
            body:
                `<head><meta charset="UTF-8"></head>` +
                `<style>
                    h1 { font-size: 25pt;}
                    h2 {  font-size: 15pt;}                    
                    p { font-size: 10pt;}
                    body { font-family: 'Times New Roman'; font-weight: 800; }
                    p { font-family: 'Times New Roman'; font-weight: 200; }
                </style>` +
                html,
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(pdf),
        };
        const res_ = await fetch(process.env.REACT_APP_API_PDF || '', requestOptions);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        download(await res_.blob(), 'legalWizard.pdf');
    };

    return (
        <div className="flex flex-col ">
            <Button className="ml-auto w-36" onClick={onGeneratePdfClick}>
                Pobierz PDF
            </Button>
            {/*{text}*/}
            <TextEditor editorState={props.text} editable={false} setHtml={setHtml} />
        </div>
    );
}
function DocumentPdfGenerator() {
    const { isLoading, getChaptersForDocument, getGroupsForChapter, getComments } = useDocument();
    const { data: unit } = useGetUnitQuery('1');
    const [chapters, setChapters] = useState<Chapter[]>();
    const { id } = useParams();
    const [chosenChapters, setChosenChapters] = useState<Chapter[]>([]);
    const [chosenGroups, setChosenGroups] = useState<Group[]>([]);
    const [chosenParagraph, setChosenParagraph] = useState<Paragraph[]>([]);
    const [step, setStep] = useState(0);
    const [comments, setComments] = useState<Comment[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);

    const [text, setText] = useState('');
    const [html, setHtml] = useState('');

    const [splitedStrings, setSplitedStrings] = useState<string[]>([]);
    const [indexesOfComments, setIndexesOfComments] = useState<{ index: number; comment: Comment }[]>([]);
    const [indexOfInput, setIndexOfInput] = useState(0);
    const [r, setR] = useState(false);
    const [unitData, setUnitData] = useState<Unit>();
    useEffect(() => {
        (async () => {
            if (id) {
                const chapters = await getChaptersForDocument(Number(id));
                setChapters(chapters);
                const chosenChapterTmp: Chapter[] = [];
                chapters.map((chapter) => {
                    if (chapter.isRequired) {
                        chosenChapterTmp.push(chapter);
                    }
                });
                setChosenChapters(chosenChapterTmp);
                const comments = await getComments();
                setComments(comments);
            }
        })();
    }, []);

    useEffect(() => {
        if (step == 1) {
            (async () => {
                for (let i = 0; i < chosenChapters.length; i++) {
                    const groups = await getGroupsForChapter(chosenChapters[i].idChapter);
                    setChosenGroups((prevState) => {
                        return [...prevState, ...groups];
                    });
                }
            })();
        }
    }, [step]);

    const replaceUnitData = (text: string) => {
        let tmp = text;
        console.log(unit);
        tmp = tmp.replaceAll('{Nazwa jednostki}', unit?.unitName || '');
        tmp = tmp.replaceAll('{organ wykonawczy}', unit?.executiveOrgan || '');
        tmp = tmp.replaceAll('{Województwo}', unit?.stateName || '');
        tmp = tmp.replaceAll('{Nazwa urzędu}', unit?.officeName || '');
        tmp = tmp.replaceAll('{Organ uchwałodawczy}', unit?.legislativeUnitName || '');
        tmp = tmp.replaceAll('{Adres}', unit?.address || '');
        return tmp;
    };
    const [paragraphsWithComments, setParagraphsWithComments] = useState<{ idPara: number; commentId: number }[]>([]);
    const findComments = () => {
        const indexes = [1, 1, 1, 1, 1, 1];
        const counter = (index: number) => {
            return `§ ${indexes[index]++}. `;
        };

        let textTmp: any = null;
        chosenParagraph.map((paragraph) => {
            if (!textTmp) {
                textTmp = JSON.parse(paragraph.text);
            } else {
                JSON.parse(paragraph.text).root.children.map((el: any) => {
                    textTmp.root.children.push(el);
                });
            }
        });
        let textWithData = JSON.stringify(textTmp);

        textWithData = replaceUnitData(textWithData);
        for (let i = 1; i <= 6; i++) {
            const stringToReplace = '{{§' + i + '}}';
            const regex = new RegExp(stringToReplace, 'g');
            textWithData = textWithData.replace(regex, () => {
                return counter(i);
            });
        }
        setText(textWithData);
        const splitedStings = textWithData.split(`"`);
        const blankPlaces: { index: number; comment: Comment }[] = [];
        splitedStings.forEach((string, index) => {
            comments.forEach((comment) => {
                const stringToReplace = `{{` + comment.idComment + `}}`;
                const regex = new RegExp(stringToReplace, 'gi');
                if (string.includes(stringToReplace)) {
                    blankPlaces.push({ index: index, comment: comment });
                }
            });
        });
        setSplitedStrings(splitedStings);
        setIndexesOfComments(blankPlaces);
        // const tmp: any[] = [];
        // comments.forEach((comment) => {
        //     const stringToReplace = '{{' + comment.idComment + '}}';
        //     chosenParagraph.forEach((para) => {
        //         console.log(JSON.parse(para.text));
        //         if (para.text.includes(stringToReplace)) {
        //             tmp.push({ idPara: para.idParagraph, commentId: comment.idComment });
        //         }
        //     });
        // });
        // setParagraphsWithComments(tmp);
    };
    return (
        <div>
            {step == 0 && (
                <div className="flex w-full flex-col">
                    <div className="mb-4 flex w-full justify-between">
                        <p className="text-base font-semibold leading-7 text-gray-900">Zaznacz elementy do wydruku</p>
                        <Button
                            onClick={() => {
                                findComments();
                                setStep(1);
                            }}
                        >
                            Dalej
                        </Button>
                    </div>
                    <div className="w-full">
                        {chapters?.map((chapter) => {
                            return (
                                <div key={chapter.idChapter} className="w-full">
                                    <ChapterElementForGenerator
                                        chapter={chapter}
                                        chosenChapters={chosenChapters}
                                        setChosenChapter={setChosenChapters}
                                        chosenGroups={chosenGroups}
                                        setChosenGroups={setChosenGroups}
                                        chosenParagraphs={chosenParagraph}
                                        setChosenParagraphs={setChosenParagraph}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            {step == 1 && (
                <Dialog open={step === 1}>
                    <DialogContent>
                        <div className="mt-4 ">
                            {indexesOfComments.length > 0 && (
                                <div className="flex flex-col space-y-4">
                                    <h4>Treść komentarza</h4>
                                    {indexesOfComments[indexOfInput].comment.value}
                                    <div className="flex w-full justify-end gap-2">
                                        <Button
                                            onClick={() => {
                                                setIndexOfInput(indexOfInput - 1);
                                            }}
                                            disabled={indexOfInput == 0}
                                        >
                                            Poprzedni komentarz
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setIndexOfInput(indexOfInput + 1);
                                            }}
                                            disabled={indexOfInput == indexesOfComments.length - 1}
                                        >
                                            Nastepny komentarz
                                        </Button>
                                    </div>
                                    <hr />
                                    <Input ref={inputRef} placeholder="Wpisz komentarz" />
                                    <Button
                                        className="ml-auto"
                                        onClick={() => {
                                            if (inputRef.current) {
                                                splitedStrings[indexesOfComments[indexOfInput].index] = splitedStrings[
                                                    indexesOfComments[indexOfInput].index
                                                ].replace(
                                                    '{{' + indexesOfComments[indexOfInput].comment.idComment + '}}',
                                                    inputRef.current.value,
                                                );
                                                inputRef.current.value = '';
                                                setSplitedStrings(splitedStrings);
                                                setR(!r);
                                            }
                                        }}
                                    >
                                        Wpisz komentarz
                                    </Button>
                                    <h4>Linia tekstu</h4>
                                    {splitedStrings[indexesOfComments[indexOfInput].index]}
                                </div>
                            )}
                        </div>

                        <div className="flex w-full justify-end gap-2">
                            <Button
                                variant="outline"
                                onClick={() => {
                                    setStep(0);
                                }}
                            >
                                Cofnij
                            </Button>
                            <Button
                                onClick={() => {
                                    setText(splitedStrings.join('"'));
                                    setStep(2);
                                }}
                            >
                                Generuj PDF
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            )}
            {step == 2 && (
                <div>
                    <PdfView text={text} />
                </div>
            )}
        </div>
    );
}

export default DocumentPdfGenerator;
