import React, { Dispatch, SetStateAction } from 'react';

import DataRenderer from '../../../shared/ui/data-renderer';
import { Card, CardContent, CardDescription } from '../../../shared/ui/ui';
import { Group } from '../../Groups/data-access/models/group';
import { useGroupsForChapterQuery } from '../../Groups/data-access/queries/get-groups-for-chapter.query';
import { Chapter } from '../data-access/models/chapter';

type ChapterListElementProps = {
    chapter: Chapter;
    edit: boolean;
    selected: number[];
    setSelected: Dispatch<SetStateAction<number[]>>;
};
const ChapterEditListElement = ({ chapter, edit, selected, setSelected }: ChapterListElementProps) => {
    const { data: groups, isLoading, isError } = useGroupsForChapterQuery(chapter.idChapter.toString());

    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <Card
                className="min-h-40 p-4 font-medium"
                style={{ backgroundColor: selected.includes(chapter.idChapter) ? 'darkgrey' : 'white' }}
            >
                <h3>{chapter.name}</h3>
                <CardContent>
                    <div
                        onClick={() => {
                            if (edit)
                                setSelected((prevState) => {
                                    if (prevState.includes(chapter.idChapter)) {
                                        return prevState.filter((el) => el != chapter.idChapter);
                                    } else {
                                        return [chapter.idChapter, ...prevState];
                                    }
                                });
                        }}
                    >
                        <div className="mt-1 text-sm">Grupy:</div>
                        <CardDescription className="font-normal text-gray-900">
                            {groups?.map((grp: Group) => {
                                return (
                                    <span key={grp.idGroup} className="ml-2 block text-sm">
                                        {grp.name}
                                    </span>
                                );
                            })}
                        </CardDescription>
                    </div>
                </CardContent>
            </Card>
        </DataRenderer>
    );
};

export default ChapterEditListElement;
