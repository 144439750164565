import React from 'react';
import { Toaster } from 'sonner';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { ReactQuery } from './react-query.component';
import AppRoutes from './routes.component';
import { AuthProvider } from './shared/utils/auth';

const App = () => {
    return (
        <>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
                <ReactQuery>
                    <AuthProvider>
                        <AppRoutes />
                        <Toaster position="bottom-right" />
                    </AuthProvider>
                </ReactQuery>
            </QueryParamProvider>
        </>
    );
};

export default App;
