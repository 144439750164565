import { toast } from 'sonner';

import { getHttpClient } from '../../../../shared/utils/http-client.ts';
import { Document } from '../models/document';
import { endpointName } from './const';

export const getAllDocuments = async (): Promise<Document[]> => {
    try {
        const response = await getHttpClient().get<Document[]>({ url: endpointName });
        const documentList: Document[] = [];

        if (response) {
            response.forEach((just) => documentList.push(just));
            return documentList;
        }

        toast.error('Brak danych.');
        throw new Error('No documents data received.');
    } catch (error) {
        toast.error(`Błąd podczas pobierania danych: ${error}`);
        throw error;
    }
};
