import { useMutation } from 'react-query';

import { deleteParagraph } from '../infrastructure/delete-paragraphs';
import { useInvalidateAllParahraphs } from './utils/invalidate-all-paragraphs';

export const useDeleteParagraphMutation = () => {
    const invalidateAll = useInvalidateAllParahraphs();

    return useMutation({
        mutationFn: deleteParagraph,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
