import { useMutation } from 'react-query';

import { createChapter } from '../infrastructure/create-chapter';
import { useInvalidateAllChapters } from './utils/invalidate-all-chapters';

export const useAddChapterhMutation = () => {
    const invalidateAll = useInvalidateAllChapters();

    return useMutation({
        mutationFn: createChapter,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
