import { useMutation } from 'react-query';

import { updateComment } from '../infrastructure/update-comments';
import { useInvalidateAllComments } from './utils/invalidate-all-comments';

export const useUpdateCommentMutation = () => {
    const invalidateAll = useInvalidateAllComments();

    return useMutation({
        mutationFn: updateComment,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
