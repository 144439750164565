import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import DataRenderer from '../../../shared/ui/data-renderer';
import { Header, TableContent } from '../../../shared/ui/Table/table-content';
import { routes } from '../../../shared/utils/routes';
import JustificationListElement from '../components/justyfication-list-element.component';
import { Justification } from '../data-access/models/justification';
import { useDeleteJustificationMutation } from '../data-access/mutatuions/delete-justifications.mutation';
import { useGetJustificationsQuery } from '../data-access/queries/get-all-justifications.query';

const JustificationList = () => {
    const navigate = useNavigate();
    const { mutateAsync: deleteJustification } = useDeleteJustificationMutation();
    const { data: justifications, isLoading, isError } = useGetJustificationsQuery();

    const tableHeaders: Header[] = [{ id: 1, title: 'Nazwa' }];

    const handleAddNew = () => {
        navigate({
            pathname: routes.justification.add?.path,
        });
    };

    const handleEdit = (idJustification: number) => {
        navigate({
            pathname: routes.justification.edit?.url(idJustification.toString()),
        });
    };

    const handleDelete = async (justification: Justification) => {
        try {
            await deleteJustification(justification);
            toast.success('Uzasadnienie zostało pomyślnie usunięty');
        } catch (e) {
            toast.error('Nie udało się usunąć uzasadnienia');
        }
    };

    const handlePreview = (idJustification: number) => {
        navigate({
            pathname: routes.justification.details?.url(idJustification.toString()),
        });
    };

    const sortedJustifications: Justification[] = useMemo(() => {
        return justifications
            ? [...justifications].sort(
                  (a, b) => new Date(b.updatedTime!).getTime() - new Date(a.updatedTime!).getTime(),
              )
            : [];
    }, [justifications]);

    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <TableContent
                headingTitle="Uzasadnienia"
                headingAddLabel="Dodaj nowe uzasadnienie"
                onHeadingAdd={handleAddNew}
                tableHeaders={tableHeaders}
                tableBodyContent={sortedJustifications.map((justy: Justification) => {
                    return (
                        <JustificationListElement
                            key={justy.idJustification}
                            justification={justy}
                            onPreview={handlePreview}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                    );
                })}
            />
        </DataRenderer>
    );
};

export default JustificationList;
