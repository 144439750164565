import { useState } from 'react';

import { Login } from '../features/auth/data-access/models/login';
import { Chapter } from '../features/Chapters/data-access/models/chapter';
import { ChapterGroup } from '../features/Chapters/data-access/models/chapter-group';
import { Comment } from '../features/Comments/data-access/models/comment';
import { Document } from '../features/Documents/data-access/models/document';
import { DocumentChapterGroup } from '../features/Documents/data-access/models/document-chapter-group';
import { Group } from '../features/Groups/data-access/models/group';
import { GroupParagraph } from '../features/Groups/data-access/models/group-paragraph';
import { Justification } from '../features/Justifications/data-access/models/justification';
import { Paragraph } from '../features/Paragraphs/data-access/models/paragraph';

const apiRoutes = {
    login: 'User/UserLogin',
    document: 'Document',
};

function UseApi() {
    const [isLoading, setIsLoading] = useState(false);

    const getPostHeader = (body: any) => {
        const header = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
        };
        return header;
    };
    const login = async (login: Login) => {
        const response: Response = await fetch(
            process.env.REACT_APP_API_ADDRESS + apiRoutes.login,
            getPostHeader(login),
        );
        return response;
    };
    const getDocumentList = async () => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'Document');
        setIsLoading(false);
        return response;
    };

    const markAsDeletedDocument = async (document: Document) => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + `Document/Delete`, getPostHeader(document));
        setIsLoading(false);
        return response;
    };

    const getChapters = async () => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'Chapter');
        setIsLoading(false);
        return response;
    };
    const getChapterForDocumentById = async (documentId: number) => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + `Chapter/byDocumentId/${documentId}`);
        setIsLoading(false);
        return response;
    };

    const createChapter = async () => {
        setIsLoading(true);
        const chapter: Chapter = {
            current: false,
            idDocument: 0,
            isDeleted: false,
            isRequired: false,
            isTemplate: false,
            order: 0,
            name: '',
            idChapter: 0,
            tags: [],
            chapterGroups: [],
            documentChapters: [],
        };
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + `Chapter`, getPostHeader(chapter));
        setIsLoading(false);
        return response;
    };
    const createParagraph = async () => {
        setIsLoading(true);
        const Paragraph: Paragraph = {
            current: false,
            group: 0,
            idChapter: 1,
            idParagraph: 0,
            isDeleted: false,
            isRequired: false,
            isTemplate: false,
            name: '',
            order: 0,
            text: '',
            tags: [],
        };
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + `Paragraph`, getPostHeader(Paragraph));
        setIsLoading(false);
        return response;
    };

    const createJustification = async () => {
        setIsLoading(true);
        const justification: Justification = {
            idJustification: 0,
            isBlocked: false,
            isDeleted: false,
            isTemplate: false,
            name: '',
            text: '',
        };
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + `Justification`, getPostHeader(justification));
        setIsLoading(false);
        return response;
    };
    const updateParagraph = async (paragraph: Paragraph) => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + `Paragraph/Update`, getPostHeader(paragraph));
        setIsLoading(false);
        return response;
    };

    const updateJustification = async (justification: Justification) => {
        setIsLoading(true);
        const response = await fetch(
            process.env.REACT_APP_API_ADDRESS + `Justification/Update`,
            getPostHeader(justification),
        );
        setIsLoading(false);
        return response;
    };
    const markAsDeletedParagraph = async (paragraph: Paragraph) => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + `Paragraph/Delete`, getPostHeader(paragraph));
        setIsLoading(false);
        return response;
    };

    const markAsDeletedJustification = async (justification: Justification) => {
        setIsLoading(true);
        const response = await fetch(
            process.env.REACT_APP_API_ADDRESS + `Justification/Delete`,
            getPostHeader(justification),
        );
        setIsLoading(false);
        return response;
    };

    const getParagraphById = async (id: number) => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + `Paragraph/${id}`);
        setIsLoading(false);
        return response;
    };

    const getJustificationById = async (id: number) => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + `Justification/${id}`);
        setIsLoading(false);
        return response;
    };

    const getAllParagraphs = async () => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + `Paragraph`);
        setIsLoading(false);
        return response;
    };
    const getParagraphsForChapterById = async (chapterId: number) => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + `Paragraph/byChapterId/${chapterId}`);
        setIsLoading(false);
        return response;
    };

    const updateChapter = async (chapter: Chapter) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(chapter),
        };
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'Chapter/Update', requestOptions);
        return response;
    };

    const updateDocument = async (document: Document) => {
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'Document/Update', getPostHeader(document));
        return response;
    };
    const deleteChapter = async (chapter: Chapter) => {
        setIsLoading(true);
        chapter.isDeleted = true;
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'Chapter/Delete', getPostHeader(chapter));
        setIsLoading(false);
        return response;
    };
    const getAllComments = async () => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'Comment');
        setIsLoading(false);
        return response;
    };
    const addComment = async (comment: Comment) => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'Comment', getPostHeader(comment));
        setIsLoading(false);
        return response;
    };
    const deleteComment = async (comment: Comment) => {
        setIsLoading(true);
        comment.isDeleted = true;
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'Comment/Delete', getPostHeader(comment));
        setIsLoading(false);
        return response;
    };
    const updateComment = async (comment: Comment) => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'Comment/Update', getPostHeader(comment));
        setIsLoading(false);
        return response;
    };

    const getAllGroups = async () => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'Group');
        setIsLoading(false);
        return response;
    };
    const addGroup = async (group: Group) => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'Group', getPostHeader(group));
        setIsLoading(false);
        return response;
    };
    const updateGroup = async (group: Group) => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'Group/Update', getPostHeader(group));
        setIsLoading(false);
        return response;
    };
    const deleteChapterGroup = async (group: ChapterGroup) => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'ChapterGroup/Delete', getPostHeader(group));
        setIsLoading(false);
        return response;
    };
    const saveChapterGroup = async (chapterGroup: ChapterGroup) => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'ChapterGroup', getPostHeader(chapterGroup));
        setIsLoading(false);
        return response;
    };
    const getGroupsForChapterId = async (chapterId: number) => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + `Group/byChapterId/${chapterId}`);
        setIsLoading(false);
        return response;
    };
    const getAllParagraphsGroups = async () => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'GroupParagraph');
        setIsLoading(false);
        return response;
    };
    const getParagraphsByGroupId = async (groupId: number) => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + `Paragraph/byGroupId/${groupId}`);
        setIsLoading(false);
        return response;
    };
    const saveParagraphsGroup = async (paragraphGroup: GroupParagraph) => {
        setIsLoading(true);
        const response = await fetch(
            process.env.REACT_APP_API_ADDRESS + 'GroupParagraph',
            getPostHeader(paragraphGroup),
        );
        setIsLoading(false);
        return response;
    };
    const deleteParagraphGroup = async (paragraphGroup: GroupParagraph) => {
        setIsLoading(true);
        const response = await fetch(
            process.env.REACT_APP_API_ADDRESS + 'GroupParagraph/Delete',
            getPostHeader(paragraphGroup),
        );
        setIsLoading(false);
        return response;
    };
    const updateDocumentChaptersGroup = async (group: DocumentChapterGroup) => {
        setIsLoading(true);
        const response = await fetch(
            process.env.REACT_APP_API_ADDRESS + 'DocumentChapter/Update',
            getPostHeader(group),
        );
        setIsLoading(false);
        return response;
    };

    const getAllJustification = async () => {
        setIsLoading(false);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'Justification');
        setIsLoading(false);
        return response;
    };

    const addDocument = async () => {
        setIsLoading(true);
        const newDocument: Document = {
            blocked: false,
            description: '',
            idDocument: 0,
            idDocumentType: 1,
            idUser: 3,
            isDeleted: false,
            isValid: false,
            name: '',
        };
        const resposne = await fetch(process.env.REACT_APP_API_ADDRESS + 'Document', getPostHeader(newDocument));
        setIsLoading(false);
        return resposne;
    };
    const addDocumentChaptersGroup = async (group: DocumentChapterGroup) => {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'DocumentChapter', getPostHeader(group));
        setIsLoading(false);
        return response;
    };
    return {
        isLoading,
        login,
        getDocumentList,
        getChapters,
        getChapterForDocumentById,
        getParagraphById,
        createParagraph,
        markAsDeletedParagraph,
        getAllParagraphs,
        getParagraphsForChapterById,
        updateParagraph,
        updateChapter,
        getAllComments,
        addComment,
        deleteComment,
        updateComment,
        getAllGroups,
        saveParagraphsGroup,
        getParagraphsByGroupId,
        deleteParagprahpGroup: deleteParagraphGroup,
        getAllParagraphsGroups,
        getGroupsForChapterId,
        saveChapterGroup,
        deleteChapter,
        createChapter,
        updateGroup,
        addGroup,
        deleteChapterGroup,
        updateDocumentChaptersGroup,
        addDocumentChaptersGroup,
        addDocument,
        updateDocument,
        getAllJustification,
        createJustification,
        getJustificationById,
        updateJustification,
        markAsDeletedJustification,
        markAsDeletedDocument,
    };
}
export default UseApi;
