import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import {
    Button,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuTrigger,
    Input,
    Label,
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
    Textarea,
} from '../../../shared/ui/ui';
import { routes } from '../../../shared/utils/routes';

import { ChapterFormValues } from '../components/chapter-form.schema';
import { Chapter } from '../data-access/models/chapter';
import { useAddChapterhMutation } from '../data-access/mutatuions/add-chapter.mutation';
import { useAddChapterGroupMutation } from '../data-access/mutatuions/add-chapter-group.mutation';
import { ChapterGroup } from '../data-access/models/chapter-group';
import ItemSelector from '../../../shared/ui/item-selector';
import { useGetGroupsQuery } from '../../Groups/data-access/queries/get-all-groups.query';
import { Group } from '../../Groups/data-access/models/group';
import GroupEditListElement from '../../Groups/components/group-edit-list.component';
import { ChevronDown } from 'lucide-react';

const ChapterAddView = () => {
    const navigate = useNavigate();

    const { data: allQueryGroups } = useGetGroupsQuery();

    const [allChapters, setAllChapters] = useState<Group[]>([]);
    const [chapters, setChapters] = useState<Group[]>([]);
    const [selectedFromAllChapters, setSelectedFromAllChapters] = useState<number[]>([]);
    const [selectedFromUsedChapters, setSelectedFromUsedChapters] = useState<number[]>([]);
    const [isRequired, setIsRequired] = useState(false);

    const { mutateAsync: addChapter } = useAddChapterhMutation();
    const { mutateAsync: addChapterGroup } = useAddChapterGroupMutation();
    const { register, handleSubmit, reset } = useForm<ChapterFormValues>();

    useEffect(() => {
        if (Array.isArray(allQueryGroups)) {
            setAllChapters(allQueryGroups);
        }
    }, [allQueryGroups]);

    async function onSubmit(data: ChapterFormValues) {
        try {
            const chapterData: Chapter = {
                ...data,
                isRequired,
                tags: [],
                chapterGroups: [],
                documentChapters: [],
            };
            const createdChapter = (await addChapter(chapterData)) as Chapter;
            const chapterId = createdChapter.idChapter;

            const chapterGroups: ChapterGroup[] = selectedFromAllChapters.map((id, index) => {
                return {
                    idGroup: id,
                    order: index + 1,
                    idChapterGroup: 0,
                    idChapter: chapterId,
                };
            });

            for (const chapterGroup of chapterGroups) {
                await addChapterGroup(chapterGroup);
            }
            toast.success('Rozdział został zaktualizowany.');
            navigate({ pathname: routes.chapter.path });
        } catch (error) {
            toast.error(`Błąd przy aktualizacji rozdziału.`);
        }
    }

    const requiredListName: string[] = ['Nieobowiązkowy', 'Obowiązkowy'];

    return (
        <div className="space-y-8">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="border-b border-gray-900/10 pb-8">
                    <div className="mb-2 flex items-center justify-between">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Dane rozdziału</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">Wypełnij dane rozdziału.</p>
                        </div>
                        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <Button type="submit">Zapisz</Button>
                        </div>
                    </div>
                    <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4  sm:grid-cols-6">
                        <div className="sm:col-span-6">
                            <Label>Nazwa</Label>
                            <div className="mt-2">
                                <div className="flex rounded-md shadow-sm">
                                    <Input {...register('name')} />
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <Label>Status</Label>
                            <div className="mt-2">
                                <div className="flex rounded-md shadow-sm ">
                                    <Select onValueChange={(value) => setIsRequired(value === '1')}>
                                        <SelectTrigger className="flex justify-between">
                                            <SelectValue placeholder={isRequired ? 'Obowiązkowy' : 'Nieobowiązkowy'} />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                {requiredListName.map((isReq, index) => (
                                                    <SelectItem key={isReq} value={index.toString()}>
                                                        {isReq}
                                                    </SelectItem>
                                                ))}
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-6">
                            <Label>Opis</Label>
                            <div className="mt-2">
                                <Textarea />
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div className="sm:col-span-6">
                <ItemSelector
                    allItemsQuery={allChapters}
                    assignedItemsQuery={chapters}
                    itemType="Grupy"
                    itemKey="idGroup"
                    itemNameKey="name"
                    selectedFromAllItems={selectedFromAllChapters}
                    setSelectedFromAllItems={setSelectedFromAllChapters}
                    selectedFromUsedItems={selectedFromUsedChapters}
                    setSelectedFromUsedItems={setSelectedFromUsedChapters}
                    renderListItem={(item, selected, setSelected) => (
                        <GroupEditListElement
                            key={item.idGroup}
                            group={item}
                            selected={selected}
                            setSelected={setSelected}
                            edit={true}
                        />
                    )}
                />
            </div>
        </div>
    );
};

export default ChapterAddView;
