import React, { useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import ToolbarPlugin from './ToolbarPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { TRANSFORMERS } from '@lexical/markdown';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import {
    $createParagraphNode,
    $createRangeSelection,
    $createTextNode,
    $getRoot,
    $getSelection,
    $setSelection,
    EditorState,
    EditorThemeClasses,
} from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';
import { parseEditorState } from 'lexical/LexicalUpdates';
import { getDOMTextNode } from 'lexical/LexicalUtils';

function TextEditor(props: any) {
    const theme: EditorThemeClasses = {
        // Theme styling goes here
    };

    function MyCustomAutoFocusPlugin() {
        const [editor] = useLexicalComposerContext();

        useEffect(() => {
            // Focus the editor when the effect fires!
            //editor.focus();
            editor.update(() => {
                const htmlString = $generateHtmlFromNodes(editor, null);
                if (props.setHtml) {
                    props.setHtml(htmlString);
                }
            });
            editor.update(() => {
                if (props.selection) {
                }
            });
        }, [editor]);

        return null;
    }

    let text = props.editorState;
    if (props.editorState.length === 0) {
        text = `{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}`;
    }
    const editorConfig = {
        editable: props.editable,
        editorState: text,

        // The editor theme
        theme: theme,
        // Handling of errors during update
        onError(error: any) {
            throw error;
        },
        // Any custom nodes go here
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
        ],
    };

    function OnChangePlugin({ onChange }: any) {
        // Access the editor through the LexicalComposerContext
        const [editor] = useLexicalComposerContext();
        // Wrap our listener in useEffect to handle the teardown and avoid stale references.
        useEffect(() => {
            // most listeners return a teardown function that can be called to clean them up.
            return editor.registerUpdateListener(({ editorState }) => {
                editor.update(() => {
                    const html = $generateHtmlFromNodes(editor);
                });
                // call onChange here to pass the latest state up to the parent.
                onChange(editorState);
            });
        }, []);
        return <></>;
    }
    function onChange(editorState: any) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        props.setEditorState(JSON.stringify(editorState));
    }
    const [html, setHtml] = useState('');
    function download(blob: any, filename: any) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }
    const onGeneratePdfClick = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [editor] = useLexicalComposerContext();
        const pdf = {
            body: `<head><meta charset="UTF-8"></head>` + html,
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(pdf),
        };
        const res_ = await fetch('http://127.0.0.1:8000/', requestOptions);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        download(await res_.blob(), 'out.pdf');
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (
        <div style={{ fontFamily: 'serif', height: '100%', overflowX: 'auto', ...props.style }}>
            {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore}
                <LexicalComposer initialConfig={editorConfig}>
                    {props.editable && <ToolbarPlugin para={props.para} />}
                    <RichTextPlugin
                        contentEditable={
                            <ContentEditable style={{ marginTop: '20px', paddingLeft: '5px', minHeight: '50px' }} />
                        }
                        placeholder={<></>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <MyCustomAutoFocusPlugin />
                    {props.editable && (
                        <>
                            <HistoryPlugin />
                            <AutoFocusPlugin />
                            <ListPlugin />
                            <LinkPlugin />
                            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                            <MyCustomAutoFocusPlugin />
                            <OnChangePlugin onChange={onChange} />
                            <TabIndentationPlugin />
                        </>
                    )}
                </LexicalComposer>
            }
            {/*<button onClick={onGeneratePdfClick}>Generate Pdf</button>*/}
        </div>
    );
}

export default TextEditor;
