import HttpClient from '../../../api';

let instance: HttpClient;
export const initializeHttpClient = () => {
    instance = new HttpClient();
};

export const getHttpClient = () => {
    return instance;
};

export const useHttpClient = () => {
    return getHttpClient();
};

initializeHttpClient();
