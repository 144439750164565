import { useQuery } from 'react-query';
import { z } from 'zod';

import { queryKey } from '../documents.query-keys';
import { getAllDocuments } from '../infrastructure/get-all-documents';

export const useGetDocumentsQuery = () => {
    return useQuery({
        queryKey: queryKey.documents(),
        queryFn: getAllDocuments,
        onError(error) {
            if (error instanceof z.ZodError) {
                console.log('error from useGetJustificationsQuery', error);
            }
        },
    });
};
