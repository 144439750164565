import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'sonner';

import DataRenderer from '../../../shared/ui/data-renderer';
import { Button, Input, Label, Textarea } from '../../../shared/ui/ui';
import { routes } from '../../../shared/utils/routes';
import ChapterEditListElement from '../../Chapters/components/chapter-edit-list-element.component';
import { useGetChaptersQuery } from '../../Chapters/data-access/infrastructure/get-all-chapters';
import { Chapter } from '../../Chapters/data-access/models/chapter';
import { useChaptersForDocumentQuery } from '../../Chapters/data-access/queries/get-chapters-for-document.query';
import { DocumentSchemaFormValues } from '../components/schemas/document-form.schema';
import { Document } from '../data-access/models/document';
import { DocumentChapterGroup } from '../data-access/models/document-chapter-group';
import { useUpdateDocumentChapterMutation } from '../data-access/mutatuions/edit-document-chapter.mutation';
import { useUpdateDocumentMutation } from '../data-access/mutatuions/edit-documents.mutation';
import { useGetDocumentQuery } from '../data-access/queries/get-document.query';
import ItemSelector from '../../../shared/ui/item-selector';
import { useAuth } from '../../../shared/utils/auth';
import { useGetDocumentChaptersGroupQuery } from '../data-access/queries/get-document-chapters.query';
import { useAddDocumentChapterMutation } from '../data-access/mutatuions/add-document-chapter.mutation';

const DocumentEdit = () => {
    const { id } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();

    const { data: document } = useGetDocumentQuery(id!);
    const { data: queryChapters, refetch: refetchChapters } = useChaptersForDocumentQuery(id!);
    const { data: allQueryChapters, isLoading, isError } = useGetChaptersQuery();
    const { data: chapt } = useGetDocumentChaptersGroupQuery('2');

    const [chapters, setChapters] = useState<Chapter[] | undefined>(queryChapters);
    const [allChapters, setAllChapters] = useState<Chapter[] | undefined>(allQueryChapters);
    const [selectedFromAllChapters, setSelectedFromAllChapters] = useState<number[]>([]);
    const [selectedFromUsedChapters, setSelectedFromUsedChapters] = useState<number[]>([]);
    const [selectedChapters, setSelectedChapters] = useState<Chapter[]>([]);
    const [documentChapterGroup, setDocumentChapterGroup] = useState<DocumentChapterGroup[]>([]);

    const { mutateAsync: addDocumentGroup } = useAddDocumentChapterMutation();
    const { mutateAsync: updateDocument } = useUpdateDocumentMutation();
    const { mutateAsync: updateDocumentGroup } = useUpdateDocumentChapterMutation();

    const { register, reset, handleSubmit: handleSubmitDocs, setValue } = useForm<DocumentSchemaFormValues>();

    useEffect(() => {
        if (document) {
            reset({
                ...document,
            });
        }
    }, [document, reset]);

    useEffect(() => {
        const filterChaptersById = () => {
            const filteredChapters = queryChapters?.filter((chapter) =>
                selectedFromUsedChapters.includes(chapter.idChapter),
            );

            filteredChapters && setSelectedChapters(filteredChapters);
        };

        filterChaptersById();
    }, [queryChapters, selectedFromUsedChapters]);

    useEffect(() => {
        if (queryChapters) {
            const sortedChapters = [...queryChapters].sort((a, b) => a.order - b.order);
            setChapters(sortedChapters);
        }
        if (allQueryChapters) {
            const unusedChapters = allQueryChapters.filter(
                (chp) => !queryChapters?.some((el) => el.idChapter === chp.idChapter),
            );
            setAllChapters(unusedChapters);
        }
    }, [queryChapters, allQueryChapters]);

    useEffect(() => {
        if (chapt) {
            const filteredDocumentChapters = chapt.filter((doc: DocumentChapterGroup) => doc.idDocument === Number(id));
            setDocumentChapterGroup(filteredDocumentChapters);
        }
    }, [chapt, id]);

    const onSubmitDocs = async (data: DocumentSchemaFormValues) => {
        try {
            const docsData: Document = {
                ...data,
                documentType: null,
                user: null,
                idDocument: Number(id),
                idUser: user.idUser,
                idDocumentType: 1,
            };

            const response = (await updateDocument(docsData)) as Document;
            const documentId = response.idDocument;

            const documentChapters: DocumentChapterGroup[] = selectedChapters.map((chapter, index) => {
                const existingChapter = documentChapterGroup.find((docCh) => docCh.idChapter === chapter.idChapter);
                return {
                    idChapter: chapter.idChapter,
                    idDocument: documentId,
                    order: index + 1,
                    idDocumentChapter: existingChapter ? existingChapter.idDocumentChapter : 0,
                };
            });

            for (const documentChapter of documentChapters) {
                if (documentChapter.idDocumentChapter === 0) {
                    await addDocumentGroup(documentChapter);
                } else {
                    await updateDocumentGroup(documentChapter);
                }
            }

            toast.success('Dokument został dodany.');
            navigate({ pathname: routes.docs.path });
        } catch (error) {
            toast.error(`Błąd przy dodawaniu dokumentu.`);
        }
    };

    const handleItemsChange = (updatedItems: Chapter[]) => {
        setSelectedChapters(updatedItems);
    };

    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <form onSubmit={handleSubmitDocs(onSubmitDocs)}>
                <div className="space-y-4">
                    <div className="border-b border-gray-900/10 pb-8">
                        <div className="mb-2 flex items-center justify-between">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Dane dokumentu</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">Edytuj dane dokumentu.</p>
                            </div>
                            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                <Button type="submit">Zapisz</Button>
                            </div>
                        </div>
                        <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4  sm:grid-cols-6">
                            <div className="sm:col-span-6">
                                <Label>Nazwa</Label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <Input defaultValue={document?.name} {...register('name')} />
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-6">
                                <Label>Opis</Label>
                                <div className="mt-2">
                                    <Textarea defaultValue={document?.description} {...register('description')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ItemSelector
                            allItemsQuery={allQueryChapters!}
                            assignedItemsQuery={chapters!}
                            itemType="Rozdział"
                            itemKey="idChapter"
                            itemNameKey="name"
                            selectedFromAllItems={selectedFromAllChapters}
                            setSelectedFromAllItems={setSelectedFromAllChapters}
                            selectedFromUsedItems={selectedFromUsedChapters}
                            setSelectedFromUsedItems={setSelectedFromUsedChapters}
                            onSelectedItemsChange={handleItemsChange}
                            renderListItem={(item, selected, setSelected) => (
                                <ChapterEditListElement
                                    key={item.idChapter}
                                    chapter={item}
                                    selected={selected}
                                    setSelected={setSelected}
                                    edit={true}
                                />
                            )}
                        />
                    </div>
                </div>
            </form>
        </DataRenderer>
    );
};

export default DocumentEdit;
