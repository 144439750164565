import { useQuery } from 'react-query';
import { z } from 'zod';

import { queryKey } from '../paragraphs.query-keys';
import { getParagraphsForChapter } from '../queries/get-paragraphs-for-chapter.query';

export const useGetParagraphsforChapterQuery = (chapterId: string) => {
    return useQuery({
        queryKey: [queryKey.paragraphs(), chapterId],
        queryFn: () => getParagraphsForChapter(chapterId),
        onError(error) {
            if (error instanceof z.ZodError) {
                console.log('error from useGetParagraphsforChapterQuery', error);
            }
        },
    });
};
