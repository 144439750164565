export const endpointName = 'Group';
export const createGroupEndpoint = 'Group';
export const getGroupParagraphEndpoint = 'GroupParagraph';
export const createGroupParagraphEndpoint = 'GroupParagraph';
export const updateGroupParagraphEndpoint = 'GroupParagraph/Update';
export const getGroupEndpoint = 'Group';
export const getParagraphForGroupEndpoint = 'Paragraph/byGroupId';
export const getGroupsForChapterEndpoint = 'Group/byChapterId';
export const updateGroupEndpoint = 'Group/Update';
export const deleteGroupEndpoint = 'Group/Delete';
export const deleteGroupParagraphEndpoint = 'GroupParagraph/Delete';
