import React from 'react';

import { SideBarContent } from './sidebar-content';

function SideBar() {
    return (
        <div className="hidden border-r bg-white lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <SideBarContent />
        </div>
    );
}

export default SideBar;
