import { toast } from 'sonner';
import { getHttpClient } from '../../../../shared/utils/http-client.ts';

import { Login } from '../models/login';
import { loginEndpoint } from './const';
import { AxiosError } from 'axios';
import { LoginResponse } from '../models/login-response';
import { setTokenInCookies } from '../../../../shared/utils/cookies';

export const login = async (login: Login): Promise<LoginResponse> => {
    try {
        const response = await getHttpClient().post<LoginResponse>({ url: loginEndpoint, data: JSON.stringify(login) });

        if (response) {
            setTokenInCookies(response.token);
            return response;
        } else {
            throw new Error('No response received');
        }
    } catch (e) {
        const error = e as AxiosError;

        if (error.response) {
            if (error.response.status === 401) {
                toast.error('Błędne dane. Spróbuj ponownie');
            } else {
                toast.error(`Błąd podczas logowania: ${(error.response.data as any).message || error.message}`);
            }
        } else {
            toast.error(`Błąd podczas logowania: ${error.message}`);
        }
        throw error;
    }
};
