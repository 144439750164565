import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'sonner';

import { Button, Input, Label } from '../../../shared/ui/ui';
import { routes } from '../../../shared/utils/routes';
import { CommentFormValues } from '../components/comment-form.schema';
import { Comment } from '../data-access/models/comment';
import { useAddCommentMutation } from '../data-access/mutatuions/add-comments.mutation';
import { useAuth } from '../../../shared/utils/auth';

const CommentAddView = () => {
    const navigate = useNavigate();
    const { user } = useAuth();

    const { mutateAsync: addComment } = useAddCommentMutation();

    const { register, handleSubmit } = useForm<CommentFormValues>();

    async function onSubmit(data: CommentFormValues) {
        try {
            const commentData: Comment = {
                ...data,
                paragraphComments: [],
                idUser: user.idUser,
            };
            await addComment(commentData);
            toast.success('Komentarz został dodany.');
            navigate({ pathname: routes.comment.path });
        } catch (error) {
            toast.error(`Błąd przy zmianie nazwy. Błąd: ${error}`);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4 flex items-center justify-between">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Dane komantarza</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">Wypełnij dane komantarza.</p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <Button type="submit">Zapisz</Button>
                </div>
            </div>
            <div className="whitespace-wrap w-full py-4 pl-0 pr-0 text-sm text-gray-900 sm:pl-0 md:pl-0 md:pr-3">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <Label className="mb-1">Treść</Label>
                        <Input {...register('value')} />
                    </div>
                </div>
            </div>
        </form>
    );
};

export default CommentAddView;
