import { useEffect, useState } from 'react';

import { Chapter } from '../features/Chapters/data-access/models/chapter';
import { ChapterGroup } from '../features/Chapters/data-access/models/chapter-group';
import { Comment } from '../features/Comments/data-access/models/comment';
import { Document } from '../features/Documents/data-access/models/document';
import { DocumentChapterGroup } from '../features/Documents/data-access/models/document-chapter-group';
import { Group } from '../features/Groups/data-access/models/group';
import { GroupParagraph } from '../features/Groups/data-access/models/group-paragraph';
import { Justification } from '../features/Justifications/data-access/models/justification';
import { Paragraph } from '../features/Paragraphs/data-access/models/paragraph';
import useApi from './useApi';

function UseDocument() {
    const api = useApi();
    const [documents, setDocuments] = useState<Document[]>();

    const getDocuments = async () => {
        const res = await api.getDocumentList();
        if (res.ok) {
            return (await res.json()) as Document[];
        }
        return [];
    };

    const updateDocument = async (document: Document) => {
        const res = await api.updateDocument(document);
        if (res.ok) {
            return (await res.json()) as Document;
        }
        return null;
    };
    const getChaptersForDocument = async (documentId: number) => {
        const res = await api.getChapterForDocumentById(documentId);
        if (res.ok) {
            const chapters = (await res.json()) as Chapter[];
            chapters.sort((n1, n2) => n1.order - n2.order);
            return chapters;
        }
        return [];
    };
    const getAllChapters = async () => {
        const res = await api.getChapters();
        if (res.ok) {
            return (await res.json()) as Chapter[];
        }
        return [];
    };

    const getParagraph = async (id: number) => {
        const res = await api.getParagraphById(id);
        if (res.ok) {
            return (await res.json()) as Paragraph;
        }
    };
    const createNewParagraph = async () => {
        const res = await api.createParagraph();
        if (res.ok) {
            return (await res.json()) as Paragraph;
        }
    };

    const createNewJustification = async () => {
        const res = await api.createJustification();
        if (res.ok) {
            return (await res.json()) as Justification;
        }
    };
    const addNewChapter = async () => {
        const res = await api.createChapter();
        if (res.ok) {
            return (await res.json()) as Chapter;
        }
    };
    const editParagraph = async (paragraph: Paragraph) => {
        const res = await api.updateParagraph(paragraph);
        if (res.ok) {
            return (await res.json()) as Paragraph;
        }
    };
    const editJustification = async (justification: Justification) => {
        const res = await api.updateJustification(justification);
        if (res.ok) {
            return (await res.json()) as Justification;
        }
    };
    const deleteParagraph = async (paragraph: Paragraph) => {
        const tmp = paragraph;
        tmp.isDeleted = true;
        const res = await api.markAsDeletedParagraph(tmp);
        if (res.ok) {
            return res;
        }
    };

    const deleteJustification = async (justification: Justification) => {
        const tmp = justification;
        tmp.isDeleted = true;
        const res = await api.markAsDeletedJustification(tmp);
        if (res.ok) {
            return res;
        }
    };
    const getParagraphs = async () => {
        const res = await api.getAllParagraphs();
        if (res.ok) {
            return (await res.json()) as Paragraph[];
        }
        return [];
    };

    const getJustification = async (id: number) => {
        const res = await api.getJustificationById(id);
        if (res.ok) {
            return (await res.json()) as Justification;
        }
    };
    const getParagraphsForChapter = async (chapterId: number) => {
        const res = await api.getParagraphsForChapterById(chapterId);
        if (res.ok) {
            return (await res.json()) as Paragraph[];
        }
        return [];
    };

    const updateChapters = async (chapters: Chapter[]) => {
        chapters.map(async (chapter) => {
            const res = await api.updateChapter(chapter);
        });
    };

    const getComments = async () => {
        const res = await api.getAllComments();
        if (res.ok) {
            return (await res.json()) as Comment[];
        }
        return [];
    };

    const addNewComment = async (text: string) => {
        const comment: Comment = {
            idComment: 0,
            idUser: 3,
            order: 0,
            value: text,
            idMarker: 47,
            isDeleted: false,
            paragraphComments: [],
        };
        const res = await api.addComment(comment);
        if (res.ok) {
            return res;
        }
    };
    const deleteDocument = async (document: Document) => {
        document.isDeleted = true;
        const res = await api.markAsDeletedDocument(document);
        if (res.ok) {
            return res;
        }
    };
    const deleteOldChapter = async (chapter: Chapter) => {
        const res = await api.deleteChapter(chapter);
        if (res.ok) {
            return res;
        }
    };
    const deleteOldComment = async (comment: Comment) => {
        const res = await api.deleteComment(comment);
        if (res.ok) {
            return res;
        }
    };
    const updateOldComment = async (comment: Comment) => {
        const res = await api.updateComment(comment);
        if (res.ok) {
            return res;
        }
    };

    const getGroups = async () => {
        const res = await api.getAllGroups();
        if (res.ok) {
            return (await res.json()) as Group[];
        }
        return [];
    };
    const getGroupsForChapter = async (chapterId: number) => {
        const res = await api.getGroupsForChapterId(chapterId);
        if (res.ok) {
            return (await res.json()) as Group[];
        }
        return [];
    };
    const saveChapterGroups = async (groups: Group[], chapterId: number) => {
        for (let i = 0; i < groups.length; i++) {
            const tmp: ChapterGroup = {
                idChapterGroup: 0,
                idChapter: chapterId,
                idGroup: groups[i].idGroup,
                order: i,
            };
            const res = await api.saveChapterGroup(tmp);
        }
    };
    const getParagraphsForGroup = async (groupId: number) => {
        const res = await api.getParagraphsByGroupId(groupId);
        if (res.ok) {
            return (await res.json()) as Paragraph[];
        }
        return [];
    };
    const saveParagraphsToGroup = async (paragraphs: Paragraph[], idGroup: number) => {
        for (let i = 0; i < paragraphs.length; i++) {
            const tmp: GroupParagraph = {
                idGroup: idGroup,
                idGroupParagraph: 0,
                idParagraph: paragraphs[i].idParagraph,
                order: i,
            };
            await api.saveParagraphsGroup(tmp);
        }
    };
    const deleteParagraphGroup = async (paragraph: Paragraph) => {
        const response = await api.getAllParagraphsGroups();
        const grp = (await response.json()) as GroupParagraph[];
        const toDel = grp.filter((grupa) => {
            return grupa.idParagraph == paragraph.idParagraph;
        });
        if (toDel) await api.deleteParagprahpGroup(toDel[0]);
    };
    // const deleteChaptersGroup = async (grp: GroupDto) => {
    //     const respone = await api.getAllGroups()
    //
    // }
    const addNewGroup = async () => {
        const group: Group = {
            idGroup: 0,
            isDeleted: false,
            name: '',
            order: 0,
            isRequired: false,
            ChapterGroups: [],
            GroupParagraphs: [],
        };
        const response = await api.addGroup(group);
        if (response.ok) return (await response.json()) as Group;
    };
    const isLoading = api.isLoading;
    const updateChapter = api.updateChapter;
    const updateGroup = api.updateGroup;

    const updateDocumentGroup = async (grp: DocumentChapterGroup) => {
        const response = await api.updateDocumentChaptersGroup(grp);
        if (response.ok) return;
    };
    const addDocumentGroup = async (grp: DocumentChapterGroup) => {
        const response = await api.addDocumentChaptersGroup(grp);
        if (response.ok) return;
    };

    const createNewDocument = async () => {
        const res = await api.addDocument();
        if (res.ok) return (await res.json()) as Document;
    };

    const getAllJustifications = async () => {
        const res = await api.getAllJustification();
        if (res.ok) return (await res.json()) as Justification[];
        return [];
    };
    return {
        isLoading,
        getDocuments,
        getChaptersForDocument,
        getAllChapters,
        getParagraph,
        getParagraphs,
        deleteParagraph,
        getParagraphsForChapter,
        createNewParagraph,
        editParagraph,
        updateChapters,
        getComments,
        addNewComment,
        deleteOldComment,
        updateOldComment,
        getGroups,
        saveParagraphsToGroup,
        getParagraphsForGroup,
        deleteParagraphGroup,
        getGroupsForChapter,
        saveChapterGroups,
        deleteOldChapter,
        addNewChapter,
        updateGroup,
        updateChapter,
        addNewGroup,
        //deleteGroup,
        updateDocumentGroup,
        addDocumentGroup,
        createNewDocument,
        updateDocument,
        getAllJustifications,
        createNewJustification,
        getJustification,
        editJustification,
        deleteJustification,
        deleteDocument,
    };
}
export default UseDocument;
