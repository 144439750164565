import React from 'react';

import {
    Button,
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '../ui';

type AlertDialogProps = {
    type: string;
    title: string;
    description: string;
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    onConfirm: () => void;
};

export const AlertDialog = ({ type, title, isOpen, description, onOpenChange, onConfirm }: AlertDialogProps) => {
    const handleConfirm = () => {
        onConfirm();
    };

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle className="mb-2">{type}</DialogTitle>
                    <DialogDescription>{description}</DialogDescription>
                </DialogHeader>

                <DialogFooter>
                    <Button onClick={handleConfirm} variant="destructive" type="submit">
                        Potwierdź
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
