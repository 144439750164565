import React from 'react';

import { cn } from '../../../lib/utils';
import { Button } from '../ui';

export type Header = {
    id: number;
    title: string;
};

type TableContentProps = {
    headingTitle: string;
    headingAddLabel?: string;
    onHeadingAdd?: () => void;
    tableHeaders: Header[];
    tableBodyContent: React.ReactNode;
    childBeforeContent?: React.ReactNode;
};

export const TableContent = ({
    headingTitle,
    headingAddLabel,
    onHeadingAdd,
    tableHeaders,
    tableBodyContent,
    childBeforeContent,
}: TableContentProps) => {
    return (
        <div>
            <div className="mb-4 flex items-center justify-between">
                <h1 className="text-base font-semibold leading-6 text-gray-900">{headingTitle}</h1>
                {headingAddLabel && onHeadingAdd && (
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <Button onClick={onHeadingAdd}>{headingAddLabel}</Button>
                    </div>
                )}
            </div>
            {childBeforeContent}
            <div className="mt-2 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto">
                    <div className="inline-block min-w-full px-6 py-2 align-middle">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    {tableHeaders.map((header, index) => (
                                        <th
                                            key={header.id}
                                            scope="col"
                                            className={cn(
                                                'text-left text-sm font-semibold text-gray-900',
                                                index === 0 ? 'py-3.5 pl-0 pr-3' : 'px-3 py-3.5',
                                            )}
                                        >
                                            {header.title}
                                        </th>
                                    ))}
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">{tableBodyContent}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
