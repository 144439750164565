import React, { Dispatch, SetStateAction } from 'react';
import DataRenderer from '../../../shared/ui/data-renderer';
import { Card, CardContent, CardDescription } from '../../../shared/ui/ui';
import { Group } from '../../Groups/data-access/models/group';
import { Paragraph } from '../../Paragraphs/data-access/models/paragraph';
import { useGetParagraphsforChapterQuery } from '../../Paragraphs/data-access/infrastructure/get-parahraphs-for-chapter';

type GroupListElementProps = {
    group: Group;
    edit: boolean;
    selected: number[];
    setSelected: Dispatch<SetStateAction<number[]>>;
};

const GroupEditListElement = ({ group, edit, selected, setSelected }: GroupListElementProps) => {
    const idGroupString = group.idGroup ? group.idGroup.toString() : '0';

    const { data: groups, isLoading, isError } = useGetParagraphsforChapterQuery(idGroupString);

    const handleClick = () => {
        if (edit) {
            setSelected((prevState) => {
                const index = prevState.indexOf(group.idGroup);
                if (index >= 0) {
                    return prevState.filter((id) => id !== group.idGroup);
                } else {
                    return [...prevState, group.idGroup];
                }
            });
        }
    };

    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <Card
                className="min-h-40 p-4 font-medium"
                style={{ backgroundColor: selected.includes(group.idGroup) ? 'darkgrey' : 'white' }}
                onClick={handleClick}
            >
                <h3>{group.name}</h3>
                <CardContent>
                    <div className="mt-1 text-sm">Paragrafy:</div>
                    <CardDescription className="font-normal text-gray-900">
                        {groups?.map((grp: Paragraph) => (
                            <span key={grp.idParagraph} className="ml-2 block text-sm">
                                {grp.name}
                            </span>
                        ))}
                    </CardDescription>
                </CardContent>
            </Card>
        </DataRenderer>
    );
};

export default GroupEditListElement;
