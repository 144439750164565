import { useMutation } from 'react-query';

import { createGroupParagraph } from '../infrastructure/create-group-paragraph';
import { useInvalidateAllGroups } from './utils/invalidate-all-groups';

export const useAddGroupParagraphMutation = () => {
    const invalidateAll = useInvalidateAllGroups();

    return useMutation({
        mutationFn: createGroupParagraph,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
