import { useQuery } from 'react-query';
import { z } from 'zod';

import { queryKey } from '../groups.query-keys';
import { getAllGroups } from '../infrastructure/get-all-groups';

export const useGetGroupsQuery = () => {
    return useQuery({
        queryKey: queryKey.groups(),
        queryFn: getAllGroups,
        onError(error) {
            if (error instanceof z.ZodError) {
                console.log('error from useGetGroupsQuery', error);
            }
        },
    });
};
