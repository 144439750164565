import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import DataRenderer from '../../../shared/ui/data-renderer';
import {
    Button,
    Input,
    Label,
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
    Textarea,
} from '../../../shared/ui/ui';
import { routes } from '../../../shared/utils/routes';
import { ChapterFormValues } from '../components/chapter-form.schema';
import { useGetChapterQuery } from '../data-access/queries/get-chapter.query';
import { useGetGroupsQuery } from '../../Groups/data-access/queries/get-all-groups.query';
import { useGroupsForChapterQuery } from '../../Groups/data-access/queries/get-groups-for-chapter.query';
import { useGetGroupChapterParagraphsQuery } from '../data-access/queries/get-chapter-groups-paragraphs.query';
import { useUpdateChapterMutation } from '../data-access/mutatuions/edit-chapter.mutation';
import { useUpdateChapterGroupMutation } from '../data-access/mutatuions/edit-chapter-group.mutation';
import { useAddChapterGroupMutation } from '../data-access/mutatuions/add-chapter-group.mutation';
import ItemSelector from '../../../shared/ui/item-selector';
import GroupEditListElement from '../../Groups/components/group-edit-list.component';
import { Group } from '../../Groups/data-access/models/group';
import { Chapter } from '../data-access/models/chapter';
import { ChapterGroup } from '../data-access/models/chapter-group';

const ChapterEditView = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { data: chapter } = useGetChapterQuery(id!);
    const { data: queryGroups } = useGroupsForChapterQuery(id!);
    const { data: allQueryGroups, isLoading, isError } = useGetGroupsQuery();
    const { data: chapt } = useGetGroupChapterParagraphsQuery('2');

    const [groups, setGroups] = useState<Group[] | undefined>([]);
    const [allGroups, setAllGroups] = useState<Group[] | undefined>([]);
    const [selectedFromAllGroups, setSelectedFromAllGroups] = useState<number[]>([]);
    const [selectedFromUsedGroups, setSelectedFromUsedGroups] = useState<number[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
    const [chapterGroupList, setChapterGroupList] = useState<ChapterGroup[]>([]);
    const [isRequired, setIsRequired] = useState(false);

    const { mutateAsync: updateChapter } = useUpdateChapterMutation();
    const { mutateAsync: updateChapterGroup } = useUpdateChapterGroupMutation();
    const { mutateAsync: addChapterGroup } = useAddChapterGroupMutation();
    const { register, handleSubmit, reset } = useForm<ChapterFormValues>();

    const requiredListName: string[] = ['Nieobowiązkowy', 'Obowiązkowy'];

    useEffect(() => {
        if (chapter) {
            reset({ name: chapter.name ?? '', isRequired: chapter.isRequired });
            setIsRequired(chapter.isRequired);
        }
    }, [chapter, reset]);

    useEffect(() => {
        const filterGroupsById = () => {
            const filteredGroups = queryGroups?.filter((group) => selectedFromUsedGroups.includes(group.idGroup));

            filteredGroups && setSelectedGroups(filteredGroups);
        };

        filterGroupsById();
    }, [queryGroups, selectedFromUsedGroups]);

    useEffect(() => {
        if (queryGroups) {
            const sortedGroups = [...queryGroups].sort((a, b) => a.order - b.order);
            setGroups(sortedGroups);
        }
        if (allQueryGroups) {
            const unusedGroups = allQueryGroups.filter(
                (group) => !queryGroups?.some((el) => el.idGroup === group.idGroup),
            );
            setAllGroups(unusedGroups);
        }
    }, [queryGroups, allQueryGroups]);

    useEffect(() => {
        if (chapt) {
            const filteredChapterGroups = chapt.filter((group: ChapterGroup) => group.idChapter === Number(id));
            setChapterGroupList(filteredChapterGroups);
        }
    }, [chapt, id]);

    async function onSubmit(data: ChapterFormValues) {
        try {
            const chapterData = {
                ...data,
                idChapter: Number(id!),
                isRequired,
                tags: [],
                chapterGroups: [],
                documentChapters: [],
            };
            const createdChapter = (await updateChapter(chapterData)) as Chapter;
            const chapterId = createdChapter.idChapter;

            const chapterGroups = selectedGroups.map((group, index) => {
                const existingGroup = chapterGroupList.find((docCh) => docCh.idGroup === group.idGroup);
                return {
                    idGroup: group.idGroup,
                    order: index + 1,
                    idChapterGroup: existingGroup ? existingGroup.idChapterGroup : 0,
                    idChapter: chapterId,
                };
            });

            for (const chapterGroup of chapterGroups) {
                if (chapterGroup.idChapterGroup === 0) {
                    await addChapterGroup(chapterGroup);
                } else {
                    await updateChapterGroup(chapterGroup);
                }
            }

            toast.success('Rozdział został zaktualizowany.');
            navigate({ pathname: routes.chapter.path });
        } catch (error) {
            toast.error('Błąd przy aktualizacji rozdziału.');
        }
    }

    const handleItemsChange = (updatedItems: Group[]) => {
        setSelectedGroups(updatedItems);
    };

    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-8">
                    <div className="border-b border-gray-900/10 pb-8">
                        <div className="mb-2 flex items-center justify-between">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Dane rozdziału</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">Edytuj dane rozdziału.</p>
                            </div>
                            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                <Button type="submit">Zapisz</Button>
                            </div>
                        </div>
                        <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                            <div className="sm:col-span-6">
                                <Label>Nazwa</Label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ">
                                        <Input defaultValue={chapter?.name} {...register('name')} />
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-6">
                                <Label>Status</Label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ">
                                        <Select onValueChange={(value) => setIsRequired(value === '1')}>
                                            <SelectTrigger className="flex justify-between">
                                                <SelectValue
                                                    placeholder={isRequired ? 'Obowiązkowy' : 'Nieobowiązkowy'}
                                                />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {requiredListName.map((isReq, index) => (
                                                        <SelectItem key={isReq} value={index.toString()}>
                                                            {isReq}
                                                        </SelectItem>
                                                    ))}
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-6">
                                <Label>Opis</Label>
                                <div className="mt-2">
                                    <Textarea />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sm:col-span-6">
                        <ItemSelector
                            allItemsQuery={allQueryGroups!}
                            assignedItemsQuery={groups!}
                            itemType="Grupy"
                            itemKey="idGroup"
                            itemNameKey="name"
                            onSelectedItemsChange={handleItemsChange}
                            selectedFromAllItems={selectedFromAllGroups}
                            setSelectedFromAllItems={setSelectedFromAllGroups}
                            selectedFromUsedItems={selectedFromUsedGroups}
                            setSelectedFromUsedItems={setSelectedFromUsedGroups}
                            renderListItem={(item, selected, setSelected) => (
                                <GroupEditListElement
                                    key={item.idGroup}
                                    group={item}
                                    selected={selected}
                                    setSelected={setSelected}
                                    edit={true}
                                />
                            )}
                        />
                    </div>
                </div>
            </form>
        </DataRenderer>
    );
};

export default ChapterEditView;
