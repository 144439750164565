import { DialogClose } from '@radix-ui/react-dialog';
import { ArrowUp } from 'lucide-react';
import React, { useState } from 'react';

import { cn } from '../../../lib/utils';
import DataRenderer from '../../../shared/ui/data-renderer';
import { Header, TableContent } from '../../../shared/ui/Table/table-content';
import { Button, Dialog, DialogContent, DialogTrigger, Input } from '../../../shared/ui/ui';
import { useGetCommentsQuery } from '../data-access/queries/get-comments.query';
import ChooseElement from '../../../shared/ui/choose-element.component';
import { AddCommentModal } from './comment-add-modal';

type SelectCommentModalProps = {
    onSubmit: (event: any, id: number) => void;
    triggerIcon?: React.ReactElement;
};

export const SelectCommentModal = ({ onSubmit, triggerIcon }: SelectCommentModalProps) => {
    const [selectOpen, setSelectOpen] = useState(false);
    const [addCommentOpen, setAddCommentOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCommentIds, setSelectedCommentIds] = useState<number[]>([]);

    const { data: comments, isLoading, isError } = useGetCommentsQuery();

    const tableHeaders: Header[] = [{ id: 1, title: 'Nazwa' }];

    const filteredComments = comments
        ? comments.filter((comment) => comment.value.toLowerCase().includes(searchTerm))
        : [];

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleSave = () => {
        selectedCommentIds.forEach((id) => {
            onSubmit(new MouseEvent('click'), id);
        });
        setSelectedCommentIds([]);
        setSelectOpen(false);
    };

    return (
        <>
            <Dialog open={selectOpen} onOpenChange={setSelectOpen}>
                <DialogTrigger>{triggerIcon ? triggerIcon : <ArrowUp />}</DialogTrigger>
                <DialogContent
                    className={cn(
                        'h-[800px] w-full overflow-x-auto py-8 md:min-w-[900px] md:px-12 md:py-16 lg:min-w-[1200px] ',
                    )}
                >
                    <DataRenderer isLoading={isLoading} error={isError}>
                        <TableContent
                            headingTitle="Lista komentarzy"
                            headingAddLabel="Dodaj nowy komentarz"
                            onHeadingAdd={() => setAddCommentOpen(true)}
                            tableHeaders={tableHeaders}
                            childBeforeContent={
                                <div className="mt-6 flex flex-col gap-2">
                                    <Input placeholder="Wyszukaj komentarz" onChange={handleSearch} />
                                </div>
                            }
                            tableBodyContent={
                                filteredComments.length > 0 ? (
                                    filteredComments.map((comment) => (
                                        <ChooseElement
                                            key={comment.idComment}
                                            element={comment}
                                            selectedElements={selectedCommentIds}
                                            setSelectedElements={setSelectedCommentIds}
                                            onAddElement={(id) => {
                                                if (selectedCommentIds.includes(id)) {
                                                    setSelectedCommentIds(
                                                        selectedCommentIds.filter((selectedId) => selectedId !== id),
                                                    );
                                                } else {
                                                    setSelectedCommentIds([...selectedCommentIds, id]);
                                                }
                                            }}
                                            getId={(comment) => comment.idComment}
                                            getValue={(comment) => comment.value}
                                        />
                                    ))
                                ) : (
                                    <div className="flex h-[550px] w-full items-center justify-center text-black">
                                        <p>Brak komentarzy</p>
                                    </div>
                                )
                            }
                        />
                    </DataRenderer>
                    <DialogClose className="mt-4 flex justify-between">
                        <Button onClick={handleSave} className="ml-auto min-w-24">
                            Zapisz
                        </Button>
                    </DialogClose>
                </DialogContent>
            </Dialog>
            <AddCommentModal triggerIcon={<span />} isOpen={addCommentOpen} onOpenChange={setAddCommentOpen} />
        </>
    );
};
