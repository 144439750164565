import { useMutation } from 'react-query';

import { updateChapter } from '../infrastructure/update-chapter';
import { useInvalidateAllChapters } from './utils/invalidate-all-chapters';

export const useUpdateChapterMutation = () => {
    const invalidateAll = useInvalidateAllChapters();

    return useMutation({
        mutationFn: updateChapter,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
