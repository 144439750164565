import { useQueryClient } from 'react-query';
import { queryKey } from '../../units.query-key';

export const useInvalidateAllUnits = () => {
    const queryClient = useQueryClient();

    return () => {
        queryClient.invalidateQueries(queryKey.units());
    };
};
