import React, { useState, createContext, useContext, useEffect } from 'react';
import { User } from '../../features/auth/data-access/models/user';
import { getTokenFromCookies, removeTokenFromCookies } from './cookies';

type AuthContextType = {
    token: string | null;
    setToken: React.Dispatch<React.SetStateAction<string | null>>;
    removeToken: () => void;
};

type UserContextType = {
    user: User;
    setUser: React.Dispatch<React.SetStateAction<User>>;
};

export const AuthContext = createContext<AuthContextType | undefined>(undefined);
export const UserContext = createContext<UserContextType | undefined>(undefined);

export const useAuth = () => {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);

    if (!authContext) {
        throw new Error('useAuth must be used within a AuthProvider');
    }

    if (!userContext) {
        throw new Error('useAuth must be used within a UserProvider');
    }

    return {
        token: authContext.token,
        setToken: authContext.setToken,
        removeToken: authContext.removeToken,
        user: userContext.user,
        setUser: userContext.setUser,
    };
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [token, setToken] = useState<string | null>(getTokenFromCookies() || null);
    const [user, setUser] = useState<User>(() => {
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : { email: '', idUser: 0 };
    });

    useEffect(() => {
        if (user.idUser !== 0) {
            localStorage.setItem('user', JSON.stringify(user));
        }
    }, [user]);

    const removeToken = () => {
        setToken(null);
        removeTokenFromCookies();
        localStorage.removeItem('user');
    };

    return (
        <AuthContext.Provider value={{ token, setToken, removeToken }}>
            <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>
        </AuthContext.Provider>
    );
};
