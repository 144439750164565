import React from 'react';

import { Button } from './ui';

type ChooseElementProps<T> = {
    element: T;
    selectedElements: number[];
    setSelectedElements: (id: number[]) => void;
    onAddElement: (id: number) => void;
    getId: (comment: T) => number;
    getValue: (comment: T) => string;
};

const ChooseElement = <T,>(props: ChooseElementProps<T>) => {
    const { element, selectedElements, onAddElement, getId, getValue } = props;
    const elementId = getId(element);
    const buttonText = selectedElements.includes(elementId) ? 'Wybrany' : 'Wybierz';

    return (
        <tr>
            <td className="whitespace-wrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                {getValue(element)}
            </td>
            <td>
                <Button
                    size="sm"
                    className="min-w-20 text-xs"
                    variant={buttonText === 'Wybierz' ? 'outline' : 'default'}
                    onClick={(e) => {
                        onAddElement(elementId);
                        e.preventDefault();
                    }}
                >
                    {buttonText}
                </Button>
            </td>
        </tr>
    );
};

export default ChooseElement;
