import { useQueryClient } from 'react-query';

import { queryKey } from '../../chapters.query-keys';

export const useInvalidateAllChapters = () => {
    const queryClient = useQueryClient();

    return () => {
        queryClient.invalidateQueries(queryKey.chapters());
    };
};
