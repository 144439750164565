import { useQueryClient } from 'react-query';

import { queryKey } from '../../comments.query-keys';

export const useInvalidateAllComments = () => {
    const queryClient = useQueryClient();

    return () => {
        queryClient.invalidateQueries(queryKey.comments());
    };
};
