import { useMutation } from 'react-query';
import { updateUnit } from '../infrastructure/update-unit';
import { useInvalidateAllUnits } from './utils/invalidate-all-units';

export const useUpdateUnitMutation = () => {
    const invalidateAll = useInvalidateAllUnits();

    return useMutation({
        mutationFn: updateUnit,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
