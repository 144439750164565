import { toast } from 'sonner';

import { getHttpClient } from '../../../../shared/utils/http-client.ts';
import { Group } from '../models/group';
import { getGroupsForChapterEndpoint } from './const';

export const getGroupsForChapter = async (id: string): Promise<Group[]> => {
    try {
        const response = await getHttpClient().get<Group[]>({ url: `${getGroupsForChapterEndpoint}/${id}` });
        const chapterList: Group[] = [];

        if (response) {
            response.forEach((just) => chapterList.push(just));
            return chapterList;
        }

        toast.error('Brak danych.');
        throw new Error('No groups data received.');
    } catch (error) {
        toast.error(`Błąd podczas pobierania danych: ${error}`);
        throw error;
    }
};
