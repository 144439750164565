import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import DataRenderer from '../../../shared/ui/data-renderer';
import TextEditor from '../../../shared/ui/TextEditor/TextEditor';
import { Button, Input, Label } from '../../../shared/ui/ui';
import { routes } from '../../../shared/utils/routes';
import SelectJustificationModal from '../../Justifications/components/justification-select-modal.component';
import { useGetJustificationQuery } from '../../Justifications/data-access/queries/get-justification.query';
import { ParagraphFormValues } from '../components/paragraph-form.schema';
import { useUpdateParagraphMutation } from '../data-access/mutatuions/edit-paragraphs.mutation';
import { useGetParagraphQuery } from '../data-access/queries/get-paragraph.query';
import { useUpdateJustificationMutation } from '../../Justifications/data-access/mutatuions/edit-justifications.mutation';
import { JustificationhFormValues } from '../../Justifications/components/justification-form.schema';

const ParagraphEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { data: paragraph, isLoading, isError, refetch } = useGetParagraphQuery(id!);
    const { mutateAsync: updateParagraph } = useUpdateParagraphMutation();
    const { mutateAsync: updateJustification } = useUpdateJustificationMutation();
    const {
        data: justification,
        isLoading: isJustificationLoading,
        refetch: refetchJustification,
    } = useGetJustificationQuery(
        paragraph?.idJustification?.toString() ?? '0',
        !isLoading && paragraph?.idJustification !== null,
    );

    const [paragraphText, setParagraphText] = useState<string>(paragraph?.text ?? '');
    const [justificationText, setJustificationText] = useState<string>(paragraph?.justification?.text ?? '');
    const [isOpenJustificationModal, setIsOpenJustificationModal] = useState(false);
    const [idJustification, setIdJustification] = useState(0);

    const handleAddJustification = () => {
        resetJustification();
        setIsOpenJustificationModal((prev) => !prev);
    };

    const {
        register: registerParagraph,
        reset: resetParagraph,
        handleSubmit: handleSubmitParagraph,
        setValue,
    } = useForm<ParagraphFormValues>();

    const {
        register: justificationRegister,
        reset: resetJustification,
        handleSubmit: handleJustificationSubmit,
    } = useForm<JustificationhFormValues>();

    useEffect(() => {
        if (paragraph) {
            resetParagraph({
                ...paragraph,
            });
            setIdJustification(paragraph.idJustification ?? 0);
            setParagraphText(paragraph.text ?? '');
        }
    }, [paragraph, resetParagraph]);

    useEffect(() => {
        resetJustification({
            ...justification,
        });
    }, [isOpenJustificationModal, justification, resetJustification]);

    async function onSubmit(data: ParagraphFormValues) {
        const updatedData = {
            ...data,
            text: paragraphText,
        };

        try {
            await updateParagraph(updatedData);
            toast.success('Paragraf został zaktualizowany.');
            refetch();
        } catch (error) {
            toast.error('Błąd przy aktualizacji paragrafu.');
        }
    }

    async function onSubmitJustification(data: JustificationhFormValues) {
        const updatedData = {
            ...data,
            text: justificationText,
        };
        try {
            await updateJustification(updatedData);
            refetch();
            refetchJustification();
            toast.success('Uzasadnienie zostało zaktualizowane.');
        } catch (error) {
            toast.error('Błąd przy aktualizacji uzasadnienia.');
        }
    }

    const onSelectJustification = async (idJustification: number) => {
        setValue('idJustification', idJustification);
        const formData: ParagraphFormValues = {
            ...paragraph!,
            idJustification: idJustification,
        };

        try {
            await updateParagraph(formData);
            refetch();
            refetchJustification();
            toast.success('Paragraf został zaktualizowany.');
            navigate({ pathname: routes.paragraph.edit?.url(id!) });
        } catch (error) {
            toast.error('Błąd przy aktualizacji paragrafu.');
        }
    };

    return (
        <>
            <DataRenderer isLoading={isLoading} error={isError}>
                <div className="space-y-4">
                    <div className="mb-4 flex items-center justify-between">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Dane paragrafu</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">Edytuj dane paragrafu.</p>
                        </div>
                        <div className="mt-4 flex gap-2 sm:ml-16 sm:mt-0 sm:flex-none">
                            <Button variant="outline" type="button" onClick={handleAddJustification}>
                                {paragraph?.idJustification ? 'Zmień uzasadnienie' : 'Dodaj uzasadnienie'}
                            </Button>
                            <Button type="submit" form="paragraphForm">
                                Zapisz
                            </Button>
                        </div>
                    </div>

                    <form onSubmit={handleSubmitParagraph(onSubmit)}>
                        <div className="whitespace-wrap w-full space-y-2 py-4 pl-0 pr-0 text-sm text-gray-900 sm:pl-0 md:pl-0 md:pr-3">
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-2">
                                    <Label className="mb-1">Nazwa</Label>
                                    <Input defaultValue={paragraph?.name} {...registerParagraph('name')} />
                                </div>
                            </div>
                            {paragraph?.text !== undefined && (
                                <TextEditor
                                    editorState={paragraph?.text}
                                    editable={true}
                                    setEditorState={setParagraphText}
                                />
                            )}
                        </div>
                    </form>
                    {paragraph?.idJustification && (
                        <DataRenderer isLoading={isJustificationLoading} error={isError}>
                            <form onSubmit={handleJustificationSubmit(onSubmitJustification)} className="border-t pt-4">
                                <div className="mb-4 flex items-center justify-between">
                                    <h1 className="text-base leading-6 text-gray-900">
                                        <span className="font-medium">Uzasadnienie</span>
                                    </h1>
                                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                        <Button type="submit">Zapisz</Button>
                                    </div>
                                </div>

                                <div className="whitespace-wrap w-full py-4 pb-12 pl-0 pr-0 text-sm text-gray-900 sm:pl-0 md:pl-0 md:pr-3">
                                    <div className="flex flex-col gap-4">
                                        <div className="space-y-4">
                                            <div className="flex flex-col gap-2">
                                                <Label className="mb-1">Nazwa</Label>
                                                <Input
                                                    defaultValue={justification?.name}
                                                    {...justificationRegister('name')}
                                                />
                                            </div>

                                            <TextEditor
                                                editorState={justification?.text}
                                                editable={true}
                                                setEditorState={setJustificationText}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </DataRenderer>
                    )}
                </div>
            </DataRenderer>

            <SelectJustificationModal
                idJustification={idJustification}
                isOpen={isOpenJustificationModal}
                onToggle={setIsOpenJustificationModal}
                onSelectJustification={onSelectJustification}
            />
        </>
    );
};

export default ParagraphEdit;
