import React, { Dispatch, SetStateAction, useState } from 'react';

import { AlertDialog } from '../../../shared/ui/Modal/alert-dialog';
import { Card } from '../../../shared/ui/ui';
import { Paragraph } from '../../Paragraphs/data-access/models/paragraph';
import { Copy } from 'lucide-react';
import { toast } from 'sonner';

type ParagraphListElementProps = {
    paragraph: Paragraph;
    onAdd?: (paragraph: Paragraph) => void;
    onRemove?: (paragraph: Paragraph) => void;
    add?: boolean;
    selected: number[];
    setSelected: Dispatch<SetStateAction<number[]>>;
};

const GroupParagraphListElement = ({ paragraph, selected, setSelected, onRemove }: ParagraphListElementProps) => {
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const handleConfirmDelete = () => {
        setIsDeleteOpen(false);
        if (onRemove) {
            onRemove(paragraph);
        }
    };

    const handleCardClick = () => {
        setSelected((prevState) => {
            if (prevState.includes(paragraph.idParagraph)) {
                return prevState.filter((id) => id !== paragraph.idParagraph);
            } else {
                return [...prevState, paragraph.idParagraph];
            }
        });
    };

    const handleCopyClick = async (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.stopPropagation();

        try {
            await navigator.clipboard.writeText(paragraph.name);
            toast.success('Skopiowano do schowka.');
        } catch (e) {
            toast.error('Spróbuj ponownie skopiować.');
        }
    };

    const backgroundColor = selected.includes(paragraph.idParagraph) ? 'darkgrey' : 'white';

    return (
        <>
            <Card onClick={handleCardClick} className="min-h-20 p-4 font-medium" style={{ backgroundColor }}>
                <div className="flex w-full justify-between">
                    <h3>{paragraph.name}</h3>
                    <Copy className="h-3 w-3 cursor-pointer" onClick={handleCopyClick} />
                </div>
            </Card>

            <AlertDialog
                type="Uwaga"
                title="Potwierdzenie Usunięcia"
                description="Czy na pewno chcesz usunąć ten paragraf? Ta operacja jest nieodwracalna."
                isOpen={isDeleteOpen}
                onOpenChange={() => setIsDeleteOpen((prev) => !prev)}
                onConfirm={handleConfirmDelete}
            />
        </>
    );
};

export default GroupParagraphListElement;
