import { toast } from 'sonner';

import { getHttpClient } from '../../../../shared/utils/http-client.ts';
import { Paragraph } from '../models/paragraph';
import { endpointName } from './const';

export const getAllParagraphs = async (): Promise<Paragraph[]> => {
    try {
        const response = await getHttpClient().get<Paragraph[]>({ url: endpointName });
        const paragraphList: Paragraph[] = [];

        if (response) {
            response.forEach((par) => paragraphList.push(par));
            return paragraphList;
        }

        toast.error('Brak danych.');
        throw new Error('No paragraphs data received.');
    } catch (error) {
        toast.error(`Błąd podczas pobierania danych: ${error}`);
        throw error;
    }
};
