import { toast } from 'sonner';

import { getHttpClient } from '../../../../shared/utils/http-client.ts';
import { getParagraphsForChapterByIdEndpoint } from '../infrastructure/const';
import { Paragraph } from '../models/paragraph';

export const getParagraphsForChapter = async (id: string): Promise<Paragraph[]> => {
    try {
        const response = await getHttpClient().get<Paragraph[]>({
            url: `${getParagraphsForChapterByIdEndpoint}/${id}`,
        });

        if (response) {
            return response;
        }

        toast.error('Brak danych.');
        throw new Error('No paragraphs data received.');
    } catch (error) {
        toast.error(`Błąd podczas pobierania danych: ${error}`);
        throw error;
    }
};
