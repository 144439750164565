import { useQuery } from 'react-query';
import { z } from 'zod';

import { getAllJustifications } from '../infrastructure/get-all-justifications';
import { queryKey } from '../justifications.query-keys';

export const useGetJustificationsQuery = () => {
    return useQuery({
        queryKey: queryKey.justifications(),
        queryFn: getAllJustifications,
        onError(error) {
            if (error instanceof z.ZodError) {
                console.log('error from useGetJustificationsQuery', error);
            }
        },
    });
};
