import React, { useState } from 'react';
import { MoreVertical } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { AlertDialog } from '../../../shared/ui/Modal/alert-dialog';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../../shared/ui/ui';
import { Chapter } from '../data-access/models/chapter';

type ChapterListElementProps = {
    chapter: Chapter;
    onPreview: (id: number) => void;
    onEdit: (id: number) => void;
    onDelete: (chapter: Chapter) => void;
};

const ChapterListElement = ({ chapter, onPreview, onEdit, onDelete }: ChapterListElementProps) => {
    const navigate = useNavigate();
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const handlePreview = () => {
        onPreview(chapter.idChapter);
    };

    const handleEdit = () => {
        onEdit(chapter.idChapter);
    };
    const options = [
        { value: 'ustawa', label: 'Ustawa' },
        { value: 'uzasadnienie', label: 'Uzasadnienie' },
        { value: 'załącznik', label: 'Załącznik' },
    ];

    const handleConfirmDelete = () => {
        onDelete(chapter);
        setIsDeleteOpen(false);
    };
    return (
        <>
            <tr key={chapter.idDocument}>
                <td className="td-name">{chapter.name}</td>
                <td className="whitespace-wrap max-w-md px-3 py-4 text-sm text-gray-500">Ustawa</td>
                <td className="whitespace-wrap max-w-md px-3 py-4 text-sm text-gray-500">
                    {chapter.isRequired ? 'Obowiązkowy' : 'Nieobowiązkowy'}
                </td>
                <td className="flex items-center justify-end py-4">
                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <MoreVertical className="mr-1 h-4 w-4" />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuItem onClick={handlePreview}>Podgląd</DropdownMenuItem>
                            <DropdownMenuItem onClick={handleEdit}>Edytuj</DropdownMenuItem>
                            <DropdownMenuItem onClick={() => setIsDeleteOpen(true)}>Usuń</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </td>
            </tr>

            <AlertDialog
                type="Uwaga"
                title="Potwierdzenie Usunięcia"
                description="Czy na pewno chcesz usunąć ten rozdział? Ta operacja jest nieodwracalna."
                isOpen={isDeleteOpen}
                onOpenChange={() => setIsDeleteOpen((prev) => !prev)}
                onConfirm={handleConfirmDelete}
            />
        </>
    );
};

export default ChapterListElement;
