import { useMutation } from 'react-query';

import { deleteDocument } from '../infrastructure/delete-documents';
import { useInvalidateAllDocuments } from './utils/invalidate-all-documents';

export const useDeleteDocumentMutation = () => {
    const invalidateAll = useInvalidateAllDocuments();

    return useMutation({
        mutationFn: deleteDocument,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
