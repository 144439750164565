import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import DataRenderer from '../../../shared/ui/data-renderer';
import TextEditor from '../../../shared/ui/TextEditor/TextEditor';
import { Button, Input, Label } from '../../../shared/ui/ui';
import { routes } from '../../../shared/utils/routes';
import { JustificationhFormValues } from '../components/justification-form.schema';
import { useUpdateJustificationMutation } from '../data-access/mutatuions/edit-justifications.mutation';
import { useGetJustificationQuery } from '../data-access/queries/get-justification.query';

const JustificationEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { data: justification, isLoading, isError } = useGetJustificationQuery(id!);

    const [text, setText] = useState<string>('');
    const { mutateAsync: updateJustification } = useUpdateJustificationMutation();

    const { register, reset, handleSubmit } = useForm<JustificationhFormValues>({
        defaultValues: {
            idJustification: Number(id),
            ...justification,
        },
    });

    useEffect(() => {
        if (justification) {
            reset({
                ...justification,
            });
            setText(justification.text);
        }
    }, [justification, reset]);

    async function onSubmit(data: JustificationhFormValues) {
        const updatedData = {
            ...data,
            text: text,
        };

        try {
            await updateJustification(updatedData);
            toast.success('Uzasadnienie zostało zaktualizowane.');
            navigate({ pathname: routes.justification.path });
        } catch (error) {
            toast.error(`Błąd przy aktualizacji uzasadnienia.`);
        }
    }
    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4 flex items-center justify-between">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Dane uzasadnienia</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Edytuj dane uzasadnienia.</p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <Button type="submit">Zapisz</Button>
                    </div>
                </div>
                <div className="whitespace-wrap w-full py-4 pl-0 pr-0 text-sm text-gray-900 sm:pl-0 md:pl-0 md:pr-3">
                    <div className="flex flex-col gap-4">
                        <div className="space-y-2">
                            <Label className="mb-1">Nazwa</Label>
                            <Input defaultValue={justification?.name} {...register('name')} />
                        </div>
                        <div>
                            <TextEditor
                                editorState={justification?.text}
                                editable={true}
                                setEditorState={setText}
                            ></TextEditor>
                        </div>
                    </div>
                </div>
            </form>
        </DataRenderer>
    );
};

export default JustificationEdit;
