import { useMutation } from 'react-query';

import { updateJustification } from '../infrastructure/update-justifications';
import { useInvalidateAllJustifications } from './utils/invalidate-all-justifications';

export const useUpdateJustificationMutation = () => {
    const invalidateAll = useInvalidateAllJustifications();

    return useMutation({
        mutationFn: updateJustification,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
