import { toast } from 'sonner';

import { getHttpClient } from '../../../../shared/utils/http-client.ts';
import { Comment } from '../models/comment';
import { getCommentEndpoint } from './const';

export const getCommentById = async (id: string): Promise<Comment> => {
    try {
        const response = await getHttpClient().get<Comment>({ url: `${getCommentEndpoint}/${id}` });

        if (response) {
            return response;
        }

        toast.error('Brak danych.');
        throw new Error('No justification data received.');
    } catch (error) {
        toast.error(`Błąd podczas pobierania danych: ${error}`);
        throw error;
    }
};
