import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'sonner';

import DataRenderer from '../../../shared/ui/data-renderer';
import { Button, Input, Label, Textarea } from '../../../shared/ui/ui';
import { routes } from '../../../shared/utils/routes';
import ChapterEditListElement from '../../Chapters/components/chapter-edit-list-element.component';
import { useGetChaptersQuery } from '../../Chapters/data-access/infrastructure/get-all-chapters';
import { Chapter } from '../../Chapters/data-access/models/chapter';
import { DocumentSchemaFormValues } from '../components/schemas/document-form.schema';
import { Document } from '../data-access/models/document';
import { DocumentChapterGroup } from '../data-access/models/document-chapter-group';
import { useAddDocumentChapterMutation } from '../data-access/mutatuions/add-document-chapter.mutation';
import { useAddDocumentMutation } from '../data-access/mutatuions/add-documents.mutation';
import ItemSelector from '../../../shared/ui/item-selector';
import { useAuth } from '../../../shared/utils/auth';
const DocumentAddView = () => {
    const navigate = useNavigate();
    const { user } = useAuth();

    const { data: allQueryChapters, isLoading, isError, refetch } = useGetChaptersQuery();
    const { mutateAsync: addDocument } = useAddDocumentMutation();
    const { mutateAsync: addDocumentGroup } = useAddDocumentChapterMutation();

    const [chapters, setChapters] = useState<Chapter[] | undefined>([]);
    const [allChapters, setAllChapters] = useState<Chapter[] | undefined>(allQueryChapters);
    const [selectedChapters, setSelectedChapters] = useState<Chapter[]>([]);
    const [selectedFromAllChapters, setSelectedFromAllChapters] = useState<number[]>([]);
    const [selectedFromUsedChapters, setSelectedFromUsedChapters] = useState<number[]>([]);

    const { register, handleSubmit: handleSubmitDocs } = useForm<DocumentSchemaFormValues>();

    useEffect(() => {
        if (allQueryChapters) {
            setAllChapters(allQueryChapters);
        }
    }, [allQueryChapters]);

    async function onSubmitDocs(data: DocumentSchemaFormValues) {
        try {
            const docsTry: Document = {
                ...data,
                idUser: user.idUser,
                user: null,
                idDocumentType: 1,
            };

            const response = (await addDocument(docsTry)) as Document;
            const documentId = response.idDocument;

            const documentChapters: DocumentChapterGroup[] = selectedFromAllChapters.map((id, index) => {
                return {
                    idChapter: id,
                    idDocument: documentId,
                    order: index + 1,
                    idDocumentChapter: 0,
                };
            });

            for (const documentChapter of documentChapters) {
                await addDocumentGroup(documentChapter);
            }

            toast.success('Dokument został dodany.');
            navigate({ pathname: routes.docs.path });
        } catch (error) {
            toast.error(`Błąd przy dodawaniu dokumentu.`);
        }
    }

    const handleSelectedChaptersChange = (newSelectedChapters: Chapter[]) => {
        setSelectedChapters(newSelectedChapters);
    };

    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <form onSubmit={handleSubmitDocs(onSubmitDocs)}>
                <div className="space-y-8">
                    <div className="border-b border-gray-900/10 pb-8">
                        <div className="mb-2 flex items-center justify-between">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Dane dokumentu</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">Wypełnij dane dokumentu.</p>
                            </div>
                            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                <Button type="submit">Zapisz</Button>
                            </div>
                        </div>
                        <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                            <div className="sm:col-span-6">
                                <Label>Nazwa</Label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm">
                                        <Input {...register('name')} />
                                    </div>
                                </div>
                            </div>

                            <div className="sm:col-span-6">
                                <Label>Opis</Label>
                                <div className="mt-2">
                                    <Textarea {...register('description')} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sm:col-span-6">
                        <ItemSelector
                            allItemsQuery={allQueryChapters!}
                            assignedItemsQuery={chapters}
                            itemType="Rozdział"
                            itemKey="idChapter"
                            itemNameKey="name"
                            onSelectedItemsChange={handleSelectedChaptersChange}
                            selectedFromAllItems={selectedFromAllChapters}
                            setSelectedFromAllItems={setSelectedFromAllChapters}
                            selectedFromUsedItems={selectedFromUsedChapters}
                            setSelectedFromUsedItems={setSelectedFromUsedChapters}
                            renderListItem={(item, selected, setSelected) => (
                                <ChapterEditListElement
                                    key={item.idChapter}
                                    chapter={item}
                                    selected={selected}
                                    setSelected={setSelected}
                                    edit={true}
                                />
                            )}
                        />
                    </div>
                </div>
            </form>
        </DataRenderer>
    );
};

export default DocumentAddView;
