import { useQuery } from 'react-query';
import { z } from 'zod';

import { queryKey } from '../groups.query-keys';
import { getGroupParagraphs } from '../infrastructure/get-group-paragraph';

export const useGetGroupParagraphsQuery = (groupId: string) => {
    return useQuery({
        queryKey: [queryKey.groups(), groupId],
        queryFn: () => getGroupParagraphs(groupId),
        onError(error) {
            if (error instanceof z.ZodError) {
                console.log('error from useGetGroupQuery', error);
            }
        },
    });
};
