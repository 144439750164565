import { toast } from 'sonner';

import { getHttpClient } from '../../../../shared/utils/http-client.ts';
import { endpointName } from '../infrastructure/const';
import { Chapter } from '../models/chapter';

export const getAllChapters = async (): Promise<Chapter[]> => {
    try {
        const response = await getHttpClient().get<Chapter[]>({ url: endpointName });
        const chapterList: Chapter[] = [];

        if (response) {
            response.forEach((just) => chapterList.push(just));
            return chapterList;
        }

        toast.error('Brak danych.');
        throw new Error('No chapters data received.');
    } catch (error) {
        toast.error(`Błąd podczas pobierania danych: ${error}`);
        throw error;
    }
};
