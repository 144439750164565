import { useQuery } from 'react-query';
import { z } from 'zod';

import { queryKey } from '../groups.query-keys';
import { getGroupsForChapter } from '../infrastructure/get-groups-for-chapter';

export const useGroupsForChapterQuery = (chapterId: string) => {
    return useQuery({
        queryKey: [queryKey.groups(), chapterId],
        queryFn: () => getGroupsForChapter(chapterId),
        onError(error) {
            if (error instanceof z.ZodError) {
                console.log('error from useGroupsForChapterQuery', error);
            }
        },
    });
};
