import React from 'react';

import { cn } from '../../../lib/utils';
import { routes } from '../../../shared/utils/routes';
import { Link } from 'react-router-dom';

type SideBarContentProps = {
    onLinkClick?: () => void;
};

export const SideBarContent = ({ onLinkClick }: SideBarContentProps) => {
    const isActive = (path: string) => {
        const currentPath = location.pathname;

        return currentPath === path || currentPath.startsWith(path);
    };
    return (
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-2 pb-4 lg:px-6 ">
            <div className="flex h-16 shrink-0 items-center">
                <img className="h-10 w-10 rounded-md object-cover" src="/logo.png" alt="Logo" />
            </div>
            <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                        <ul role="list" className="-mx-2 space-y-1">
                            {Object.entries(routes)
                                .filter(([key]) => key !== 'login' && key !== 'register')
                                .map(([key, value]) => {
                                    if (value.path) {
                                        return (
                                            <li key={key}>
                                                <Link
                                                    to={value.path}
                                                    className={cn(
                                                        isActive(value.path)
                                                            ? 'bg-gray-200 text-black'
                                                            : 'text-black-200 hover:bg-gray-200',
                                                        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                                    )}
                                                    onClick={onLinkClick}
                                                >
                                                    {value.name}
                                                </Link>
                                            </li>
                                        );
                                    } else if (value) {
                                        return (
                                            <li key={key}>
                                                <Link
                                                    to={value.path}
                                                    className={cn(
                                                        isActive(value.path)
                                                            ? 'bg-gray-200 text-black'
                                                            : 'text-black-200 hover:bg-gray-200',
                                                        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                                    )}
                                                    onClick={onLinkClick}
                                                >
                                                    {value.name}
                                                </Link>
                                            </li>
                                        );
                                    }
                                    return null;
                                })}
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    );
};
