import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'sonner';

import { Button, Input, Label } from '../../../shared/ui/ui';
import { routes } from '../../../shared/utils/routes';
import { Paragraph } from '../../Paragraphs/data-access/models/paragraph';
import { useGetParagraphsQuery } from '../../Paragraphs/data-access/queries/get-all-paragraphs.query';
import { GroupFormValues } from '../components/group-form.schema';
import GroupParagraphListElement from '../components/group-paragrpah-list-element.component';
import { Group } from '../data-access/models/group';
import { GroupParagraph } from '../data-access/models/group-paragraph';
import { useAddGroupMutation } from '../data-access/mutatuions/add-group.mutation';
import { useAddGroupParagraphMutation } from '../data-access/mutatuions/add-group-paragraph.mutation';
import ItemSelector from '../../../shared/ui/item-selector';
import DataRenderer from '../../../shared/ui/data-renderer';

const GroupAddView = () => {
    const navigate = useNavigate();
    const { data: allQueryParagraphs, isLoading, isError } = useGetParagraphsQuery();
    const { mutateAsync: addGroup } = useAddGroupMutation();
    const { mutateAsync: addGroupParagraph } = useAddGroupParagraphMutation();

    const [selectedFromAllGroups, setSelectedFromAllGroups] = useState<number[]>([]);
    const [selectedFromUsedGroups, setSelectedFromUsedGroups] = useState<number[]>([]);

    const [allParagraphs, setAllParagraphs] = useState<Paragraph[]>([]);
    const [paragraphs, setParagraphs] = useState<Paragraph[]>([]);
    const { register, handleSubmit } = useForm<GroupFormValues>();
    useEffect(() => {
        if (Array.isArray(allQueryParagraphs)) {
            setAllParagraphs(allQueryParagraphs);
        }
    }, [allQueryParagraphs]);

    async function onSubmit(data: GroupFormValues) {
        try {
            const groupData: Group = {
                ...data,
                ChapterGroups: [],
                GroupParagraphs: [],
            };
            const createdGroup = (await addGroup(groupData)) as Group;
            const groupId = createdGroup.idGroup;

            const groupParagraphs: GroupParagraph[] = selectedFromAllGroups.map((id, index) => {
                return {
                    idGroup: groupId,
                    idParagraph: id,
                    order: index + 1,
                    idGroupParagraph: 0,
                };
            });

            for (const groupParagraph of groupParagraphs) {
                await addGroupParagraph(groupParagraph);
            }

            toast.success('Grupa została dodana.');
            navigate({ pathname: routes.group.path });
        } catch (error) {
            toast.error(`Błąd przy tworzeniu grupy`);
        }
    }

    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-8">
                    <div className="border-b border-gray-900/10 pb-8">
                        <div className="mb-2 flex items-center justify-between">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Dane grupy</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">Wypełnij dane grupy.</p>
                            </div>
                            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                <Button type="submit">Zapisz</Button>
                            </div>
                        </div>
                        <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <Label>Nazwa</Label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm sm:max-w-md">
                                        <Input {...register('name')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ItemSelector
                        allItemsQuery={allQueryParagraphs!}
                        assignedItemsQuery={paragraphs}
                        itemType="Paragraf"
                        itemKey="idParagraph"
                        itemNameKey="name"
                        selectedFromAllItems={selectedFromAllGroups}
                        setSelectedFromAllItems={setSelectedFromAllGroups}
                        selectedFromUsedItems={selectedFromUsedGroups}
                        setSelectedFromUsedItems={setSelectedFromUsedGroups}
                        renderListItem={(item, selected, setSelected) => (
                            <GroupParagraphListElement
                                selected={selected}
                                setSelected={setSelected}
                                key={item.idParagraph}
                                paragraph={item}
                                add={true}
                            />
                        )}
                    />
                </div>
            </form>
        </DataRenderer>
    );
};
export default GroupAddView;
