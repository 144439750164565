import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import useDocument from '../../../hooks/useDocument';
import DataRenderer from '../../../shared/ui/data-renderer';
import { Header, TableContent } from '../../../shared/ui/Table/table-content';
import { routes } from '../../../shared/utils/routes';
import CommentListElement from '../components/comment-list-element.component';
import { Comment } from '../data-access/models/comment';
import { useDeleteCommentMutation } from '../data-access/mutatuions/delete-comments.mutation';
import { useGetCommentsQuery } from '../data-access/queries/get-comments.query';
import { useUpdateCommentMutation } from '../data-access/mutatuions/edit-comments.mutation';

const CommentList = () => {
    const navigate = useNavigate();

    const { data: comments, isLoading, isError } = useGetCommentsQuery();
    const { mutateAsync: updateComment } = useUpdateCommentMutation();
    const { mutateAsync: deleteComment } = useDeleteCommentMutation();

    const handleDelete = async (comment: Comment) => {
        try {
            await deleteComment(comment);
            toast.success('Komentarz został pomyślnie usnuięty');
        } catch (e) {
            toast.error('Nie udało się usunąć komentarza');
        }
    };

    const handleUpdate = async (comment: Comment) => {
        try {
            await updateComment(comment);
            toast.success(`Komentarz ${comment.value} został pomyślnie zaktualizowany`);
        } catch (e) {
            toast.error('Nie udało się zaktualizować komentarza');
        }
    };

    const handleAddNew = () => {
        navigate({
            pathname: routes.comment.add?.path,
        });
    };

    const tableHeaders: Header[] = [{ id: 1, title: 'Treść' }];

    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <TableContent
                headingTitle="Lista komentarzy"
                headingAddLabel="Dodaj nowy komentarz"
                onHeadingAdd={handleAddNew}
                tableHeaders={tableHeaders}
                tableBodyContent={comments?.map((comment) => {
                    return (
                        <CommentListElement
                            key={comment.idComment}
                            comment={comment}
                            onDelete={handleDelete}
                            onSave={handleUpdate}
                        />
                    );
                })}
            />
        </DataRenderer>
    );
};
export default CommentList;
