import React, { useState } from 'react';
import { Menu } from 'lucide-react';
import { Sheet, SheetContent, SheetTrigger } from '../../../shared/ui/ui';
import { SideBarContent } from './sidebar-content';

const MobileSideBar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenChange = (open: boolean) => {
        setIsOpen(open);
    };

    const handleLinkClick = () => {
        setIsOpen(false);
    };

    return (
        <Sheet open={isOpen} onOpenChange={handleOpenChange}>
            <SheetTrigger asChild>
                <Menu size={24} />
            </SheetTrigger>
            <SheetContent side="left">
                <SideBarContent onLinkClick={handleLinkClick} />
            </SheetContent>
        </Sheet>
    );
};

export default MobileSideBar;
