import { useMutation } from 'react-query';

import { updateParagraph } from '../infrastructure/update-paragraphs';
import { useInvalidateAllParahraphs } from './utils/invalidate-all-paragraphs';

export const useUpdateParagraphMutation = () => {
    const invalidateAll = useInvalidateAllParahraphs();

    return useMutation({
        mutationFn: updateParagraph,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
