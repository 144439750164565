import { useMutation } from 'react-query';

import { createDocument } from '../infrastructure/create-documents';
import { useInvalidateAllDocuments } from './utils/invalidate-all-documents';

export const useAddDocumentMutation = () => {
    const invalidateAll = useInvalidateAllDocuments();

    return useMutation({
        mutationFn: createDocument,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
