import { useMutation } from 'react-query';

import { createParagraph } from '../infrastructure/create-paragraphs';
import { useInvalidateAllParahraphs } from './utils/invalidate-all-paragraphs';

export const useAddParagraphMutation = () => {
    const invalidateAll = useInvalidateAllParahraphs();

    return useMutation({
        mutationFn: createParagraph,
        onSuccess: () => {
            invalidateAll();
        },
    });
};
