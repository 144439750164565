import { toast } from 'sonner';

import { getHttpClient } from '../../../../shared/utils/http-client.ts';
import { Chapter } from '../models/chapter';
import { getChaptersForDocumentEndpointName } from './const';

export const getChaptersForDocument = async (id: string): Promise<Chapter[]> => {
    try {
        const response = await getHttpClient().get<Chapter[]>({ url: `${getChaptersForDocumentEndpointName}/${id}` });
        const chapterList: Chapter[] = [];

        if (response) {
            response.forEach((just) => chapterList.push(just));
            return chapterList;
        }

        toast.error('Brak danych.');
        throw new Error('No chapters data received.');
    } catch (error) {
        toast.error(`Błąd podczas pobierania danych: ${error}`);
        throw error;
    }
};
