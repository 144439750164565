import { useQuery } from 'react-query';
import { z } from 'zod';

import { queryKey } from '../chapters.query-keys';
import { getChapterById } from '../infrastructure/get-chapter';

export const useGetChapterQuery = (chapterId: string) => {
    return useQuery({
        queryKey: [queryKey.chapters(), chapterId],
        queryFn: () => getChapterById(chapterId),
        onError(error) {
            if (error instanceof z.ZodError) {
                console.log('error from useGetChapterQuery', error);
            }
        },
    });
};
