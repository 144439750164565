import { MoreVertical } from 'lucide-react';
import React, { useState } from 'react';

import { AlertDialog } from '../../../shared/ui/Modal/alert-dialog';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../../shared/ui/ui';
import { Group } from '../data-access/models/group';

type GroupListElementProps = {
    group: Group;
    onEdit: (id: number) => void;
    onDelete: (group: Group) => void;
};
const GroupListElement = ({ group, onEdit, onDelete }: GroupListElementProps) => {
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const handleEdit = () => {
        onEdit(group.idGroup);
    };

    const handleDelete = () => {
        onDelete(group);
        setIsDeleteOpen(false);
    };
    return (
        <>
            <tr key={group.idGroup}>
                <td className="td-name">{group.name}</td>
                <td className="flex items-center justify-end py-4">
                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <MoreVertical className="mr-1 h-4 w-4" />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuItem onClick={handleEdit}>Edytuj</DropdownMenuItem>
                            <DropdownMenuItem onClick={() => setIsDeleteOpen(true)}>Usuń</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </td>
            </tr>
            <AlertDialog
                type="Uwaga"
                title="Potwierdzenie Usunięcia"
                description="Czy na pewno chcesz usunąć tę grupę? Ta operacja jest nieodwracalna."
                isOpen={isDeleteOpen}
                onOpenChange={() => setIsDeleteOpen((prev) => !prev)}
                onConfirm={handleDelete}
            />
        </>
    );
};

export default GroupListElement;
