import { MoreVertical } from 'lucide-react';
import React, { useState } from 'react';

import { AlertDialog } from '../../../shared/ui/Modal/alert-dialog';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../../shared/ui/ui';
import { Justification } from '../data-access/models/justification';

type JustificationListElementProps = {
    justification: Justification;
    onPreview: (id: number) => void;
    onEdit: (id: number) => void;
    onDelete: (justification: Justification) => void;
};
const JustificationListElement = (props: JustificationListElementProps) => {
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const handlePreview = () => {
        props.onPreview(props.justification.idJustification);
    };

    const handleEdit = () => {
        props.onEdit(props.justification.idJustification);
    };
    const handleDelete = () => {
        props.onDelete(props.justification);
        setIsDeleteOpen(false);
    };

    return (
        <>
            <tr key={props.justification.idJustification}>
                <td className="td-name">{props.justification.name}</td>

                <td className="flex items-center justify-end py-4">
                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <MoreVertical className="mr-1 h-4 w-4" />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuItem onClick={handlePreview}>Podgląd</DropdownMenuItem>
                            <DropdownMenuItem onClick={handleEdit}>Edytuj</DropdownMenuItem>
                            <DropdownMenuItem onClick={() => setIsDeleteOpen(true)}>Usuń</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </td>
            </tr>
            <AlertDialog
                type="Uwaga"
                title="Potwierdzenie Usunięcia"
                description="Czy na pewno chcesz usunąć to uzasadnienie? Ta operacja jest nieodwracalna."
                isOpen={isDeleteOpen}
                onOpenChange={() => setIsDeleteOpen((prev) => !prev)}
                onConfirm={handleDelete}
            />
        </>
    );
};
export default JustificationListElement;
