import { MoreVertical } from 'lucide-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { AlertDialog } from '../../../shared/ui/Modal/alert-dialog';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../../shared/ui/ui';
import { routes } from '../../../shared/utils/routes';
import { Comment } from '../data-access/models/comment';

type CommentListElementProps = {
    comment: Comment;
    onDelete: (comment: Comment) => void;
    onSave: (comment: Comment) => void;
};
export const CommentListElement = ({ comment, onDelete, onSave }: CommentListElementProps) => {
    const navigate = useNavigate();
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const handleEdit = () => {
        navigate({ pathname: routes.comment.edit?.url(comment.idComment.toString()) });
    };

    const handleConfirmDelete = () => {
        onDelete(comment);
        setIsDeleteOpen(false);
    };

    return (
        <>
            <tr key={comment.idComment}>
                <td className="td-name">{comment.value}</td>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    {comment.paragraphComments}
                </td>
                <td className="flex items-center justify-end py-4">
                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <MoreVertical className="mr-1 h-4 w-4" />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuItem onClick={handleEdit}>Edytuj</DropdownMenuItem>
                            <DropdownMenuItem onClick={() => setIsDeleteOpen(true)}>Usuń</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </td>
            </tr>
            <AlertDialog
                type="Uwaga"
                title="Potwierdzenie Usunięcia"
                description="Czy na pewno chcesz usunąć ten komentarz? Ta operacja jest nieodwracalna."
                isOpen={isDeleteOpen}
                onOpenChange={() => setIsDeleteOpen((prev) => !prev)}
                onConfirm={handleConfirmDelete}
            />
        </>
    );
};

export default CommentListElement;
