import Cookies from 'js-cookie';

export const setTokenInCookies = (token: string) => {
    Cookies.set('token', token);
};

export const getTokenFromCookies = (): string | undefined => {
    return Cookies.get('token');
};

export const removeTokenFromCookies = () => {
    Cookies.remove('token');
};
