import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import DataRenderer from '../../../shared/ui/data-renderer';
import { Header, TableContent } from '../../../shared/ui/Table/table-content';
import { routes } from '../../../shared/utils/routes';
import ChapterListElement from '../components/chapter-list-element.component';
import { useGetChaptersQuery } from '../data-access/infrastructure/get-all-chapters';
import { Chapter } from '../data-access/models/chapter';
import { useDeleteChapterMutation } from '../data-access/mutatuions/delete-chapter.mutation';

const ChapterList = () => {
    const { data: chapters, isLoading, isError } = useGetChaptersQuery();
    const { mutateAsync: deleteChapter } = useDeleteChapterMutation();
    const navigate = useNavigate();

    const handleDelete = async (chapter: Chapter) => {
        try {
            await deleteChapter(chapter);
            toast.success('Rozdział został pomyślnie usunięty');
        } catch (error) {
            toast.error('Nie udało się usunąć rozdziału');
        }
    };

    const handleAddChapter = async () => {
        navigate({ pathname: routes.chapter.add?.path });
    };

    const handleEdit = (idChapter: number) => {
        navigate({
            pathname: routes.chapter.edit?.url(idChapter.toString()),
        });
    };

    const handlePreview = (idChapter: number) => {
        navigate({
            pathname: routes.chapter.details?.url(idChapter.toString()),
        });
    };

    const tableHeaders: Header[] = [
        { id: 1, title: 'Nazwa' },
        { id: 2, title: 'Typ' },
        { id: 3, title: 'Status' },
    ];

    const sortedChapters: Chapter[] = useMemo(() => {
        return chapters
            ? [...chapters].sort((a, b) => new Date(b.updatedTime!).getTime() - new Date(a.updatedTime!).getTime())
            : [];
    }, [chapters]);

    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <TableContent
                headingTitle="Lista rozdziałów"
                headingAddLabel=" Dodaj nowy rodział"
                onHeadingAdd={handleAddChapter}
                tableHeaders={tableHeaders}
                tableBodyContent={sortedChapters.map((chapter) => {
                    return (
                        <ChapterListElement
                            key={chapter.idChapter}
                            chapter={chapter}
                            onPreview={handlePreview}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                    );
                })}
            />
        </DataRenderer>
    );
};
export default ChapterList;
