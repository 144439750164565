import axios, { AxiosInstance, AxiosResponse } from 'axios';

type GetParams<T> = {
    url: string;
    params?: object;
    token?: string;
    extraHeaders?: Record<string, string>;
};

type Headers = {
    'Content-Type': string;
    Authorization?: string;
};

class HttpClient {
    private api: AxiosInstance;

    constructor() {
        this.api = axios.create({
            baseURL: process.env.REACT_APP_API_ADDRESS,
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    private getAuthHeaders(token?: string, extraHeaders?: Record<string, string>): Headers {
        const headers: Headers = {
            'Content-Type': 'application/json',
            ...extraHeaders,
        };
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }
        return headers;
    }

    public async get<T>({ url, params = {}, token, extraHeaders }: GetParams<T>): Promise<T> {
        try {
            const headers = this.getAuthHeaders(token, extraHeaders);
            const response: AxiosResponse<T> = await this.api.get<T>(url, { params, headers });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    public async post<T>({ url, data = '', token, extraHeaders }: GetParams<T> & { data: string }): Promise<T> {
        try {
            const headers = this.getAuthHeaders(token, extraHeaders);
            const response: AxiosResponse<T> = await this.api.post<T>(url, data, { headers });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    public async put<T>({ url, data = {}, token, extraHeaders }: GetParams<T> & { data: object }): Promise<T> {
        try {
            const headers = this.getAuthHeaders(token, extraHeaders);
            const response: AxiosResponse<T> = await this.api.put<T>(url, data, { headers });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    public async del<T>({ url, data = '', token, extraHeaders }: GetParams<T> & { data: string }): Promise<T> {
        try {
            const headers = this.getAuthHeaders(token, extraHeaders);
            const response: AxiosResponse<T> = await this.api.delete<T>(url, { data, headers });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default HttpClient;
