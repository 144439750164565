import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';

import { cn } from '../../../lib/utils';
import { Button, Dialog, DialogContent, DialogTrigger, Input, Label } from '../../../shared/ui/ui';
import { useAddCommentMutation } from '../data-access/mutatuions/add-comments.mutation';
import { CommentFormValues } from './comment-form.schema';
import { ArrowUp } from 'lucide-react';
import { useAuth } from '../../../shared/utils/auth';

type AddCommentModalProps = {
    triggerIcon?: React.ReactElement;
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
};

export const AddCommentModal = ({ triggerIcon, isOpen, onOpenChange }: AddCommentModalProps) => {
    const { user } = useAuth();
    const { mutateAsync: addComment } = useAddCommentMutation();

    const { register, handleSubmit } = useForm<CommentFormValues>({
        defaultValues: {
            idUser: user.idUser,
            order: 0,
            value: '',
            idMarker: 47,
            isDeleted: false,
            paragraphComments: [],
        },
    });

    async function onSubmit(data: CommentFormValues) {
        try {
            await addComment(data);
            onOpenChange(false);
            toast.success('Komentarz został dodany.');
        } catch (error) {
            toast.error(`Błąd przy dodawaniu komentarza. Błąd: ${error}`);
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogTrigger>{triggerIcon ? triggerIcon : <ArrowUp />}</DialogTrigger>
            <DialogContent className={cn('max-h-[800px] w-full overflow-x-auto py-8  md:py-16 ')}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col justify-start gap-4">
                        <div className="flex items-center justify-between">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Dodaj nowy komentarz</h1>
                            <Button type="button" onClick={handleSubmit(onSubmit)}>
                                Zapisz
                            </Button>
                        </div>
                        <div className="flex flex-col gap-2">
                            <Label className="mb-1">Treść</Label>
                            <Input {...register('value')} required />
                        </div>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};
