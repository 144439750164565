import React, { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'sonner';

import DataRenderer from '../../../shared/ui/data-renderer';
import TextEditor from '../../../shared/ui/TextEditor/TextEditor';
import { Button, Input, Label } from '../../../shared/ui/ui';
import { routes } from '../../../shared/utils/routes';
import { CommentFormValues } from '../components/comment-form.schema';
import { Comment } from '../data-access/models/comment';
import { useUpdateCommentMutation } from '../data-access/mutatuions/edit-comments.mutation';
import { useGetCommentQuery } from '../data-access/queries/get-comment.query';
import { useAuth } from '../../../shared/utils/auth';

const CommentEditView = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();

    const { data: comment, isLoading, isError, refetch } = useGetCommentQuery(id!);
    const { mutateAsync: updateComment } = useUpdateCommentMutation();

    const { register, handleSubmit, reset } = useForm<CommentFormValues>({
        defaultValues: {
            ...comment,
        },
    });

    useEffect(() => {
        if (comment) {
            reset(comment);
        }
    }, [comment, reset]);

    async function onSubmit(data: CommentFormValues) {
        try {
            if (!user.idUser) {
                throw new Error('User not authenticated');
            }

            const commentData: Comment = {
                ...data,
                idComment: Number(id),
                idUser: user.idUser,
                paragraphComments: comment?.paragraphComments ?? [],
            };

            await updateComment(commentData);
            toast.success('Nazwa została zmieniona.');
            refetch();
            navigate(routes.comment.path);
        } catch (error) {
            toast.error(`Błąd przy zmianie nazwy. Błąd: ${error}`);
        }
    }

    return (
        <DataRenderer isLoading={isLoading} error={isError}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4 flex items-center justify-between">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Dane komantarza</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Edytuj dane komantarza.</p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <Button type="submit">Zapisz</Button>
                    </div>
                </div>
                <div className="whitespace-wrap w-full py-4 pl-0 pr-0 text-sm text-gray-900 sm:pl-0 md:pl-0 md:pr-3">
                    <div className="flex flex-col gap-2">
                        <Label className="mb-1">Treść</Label>
                        <Input {...register('value')} defaultValue={comment?.value} />
                    </div>
                </div>
            </form>
        </DataRenderer>
    );
};

export default CommentEditView;
